
import {
  defineComponent, onMounted, PropType, ref, SetupContext,
} from 'vue';
import MultiSelect from 'primevue/multiselect';
import { DropdownOption, StringMap } from '@/types';
import { asInt } from '@/helpers';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SegmentMultiDropdown',

  components: {
    MultiSelect,
  },

  props: {
    modelValue: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    options: {
      type: Function,
      required: true,
    },

    optionValue: {
      type: String,
      required: false,
      default: 'value',
    },

    optionLabel: {
      type: String,
      required: false,
      default: 'label',
    },

    size: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: any; options: Function; optionValue: string; optionLabel: string }, { emit }: SetupContext) {
    const { t } = useI18n();
    const selectedValues = ref<any>([]);
    const listObjects = ref<any>([]);

    const handleSelected = (value: any) => {
      selectedValues.value = value;
      const valueToSet = selectedValues.value.map((item: any) => item.value);
      emit('update:modelValue', valueToSet);
    };

    onMounted(async () => {
      listObjects.value = await props.options();
      if (listObjects.value) {
        listObjects.value = listObjects.value.map((item: any) => ({
          ...item,
          [props.optionLabel]: t(item[props.optionLabel]),
        }));
      }
      if (props.modelValue && listObjects.value) {
        let dataToUse = Object.prototype.toString.call(props.modelValue) === '[object Object]' ? Object.keys(props.modelValue) : props.modelValue;
        dataToUse = dataToUse.map((item: any) => item.toString());
        selectedValues.value = listObjects.value.filter((option: any) => dataToUse.includes((option[props.optionValue].toString())));
      }
    });

    return {
      t,
      selectedValues,
      handleSelected,
      listObjects,
    };
  },
});
