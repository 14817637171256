
import { computed, defineComponent, onMounted, PropType, ref, watch, } from 'vue';
import Dropdown from 'primevue/dropdown';
import InlineMessage from 'primevue/inlinemessage';
import SegmentInputText from '@/components/segmentations/fields/SegmentInputText.vue';
import SegmentInputNumber from '@/components/segmentations/fields/SegmentInputNumber.vue';
import SegmentNumberBetween from '@/components/segmentations/fields/SegmentNumberBetween.vue';
import SegmentDateInput from '@/components/segmentations/fields/SegmentDateInput.vue';
import SegmentDateBetween from '@/components/segmentations/fields/SegmentDateBetween.vue';
import SegmentAutoComplete from '@/components/segmentations/fields/SegmentAutoComplete.vue';
import SegmentMultiDropdown from '@/components/segmentations/fields/SegmentMultiDropdown.vue';
import FieldErrors from '@/components/segmentations/FieldErrors.vue';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import SegmentDropdown from '@/components/segmentations/fields/SegmentDropdown.vue';
import PropertyDeletePanel from '@/components/segmentations/PropertyDeletePanel.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { store } from "@/store";
import { useI18n } from "vue-i18n";
import validateSegmentProperty from '@/components/segmentations/utils/utils';
import { UserState } from '@/composables/User';
import Tooltip from "primevue/tooltip";
import SegmentGeographicalArea from "@/components/segmentations/fields/SegmentGeographicalArea.vue";

export default defineComponent({
  name: 'SegmentationSimpleProperty',

  components: {
    Dropdown,
    SegmentInputText,
    FieldErrors,
    SegmentInputNumber,
    SegmentNumberBetween,
    SegmentDateInput,
    SegmentDateBetween,
    SegmentAutoComplete,
    SegmentMultiDropdown,
    SegmentGeographicalArea,
    ButtonGroup,
    PeriodSelector,
    SegmentDropdown,
    PropertyDeletePanel,
    SpmButton,
    InlineMessage,
  },

  props: {
    propertyConfig: {
      type: Object,
      required: true,
    },

    propertyDefinition: {
      type: Object,
      required: true,
    },

    fieldId: {
      type: String,
      required: true,
    },

    showDeleteProperty: {
      type: Boolean,
      required: false,
      default: false,
    },

    index: {
      type: Number,
      required: false,
      default: 0,
    },

    size: {
      type: String,
      required: false,
      default: '',
    },

    isSubProperty: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  directives: {
    tooltip: Tooltip,
  },

  emits: ['remove-config-property'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const configProperty = ref(props.propertyConfig);
    const typeProperty: any = ref(null); // define the type of the property : boolean, multipleGroupsLogicalOperators, simpleGroupsLogicalOperators
    const refreshKey = ref(0);
    const logicalOperatorsOptions: any = ref();
    const logicalOperatorDefinition = ref<any>({});
    const helpMessage = ref(false);

    const getGroupedMenuItems = (logicalOperatorArr: any[]) => {
      return logicalOperatorArr.map(operator => {
        const value = operator.type;
        const label = t(`segmentations.logicalOperators.${value}.label`);
        const items = [];

        for (const key in operator.data) {
          items.push({ label: t(operator.data[key].label), value: key, component: operator.data[key].component, group: operator.type });
        }

        return { label, value, items };
      });
    }

    const getStandardMenuItems = (logicalOperators: any) => {
      return Object.entries(logicalOperators)
        .map(([key, values]: [string, any]) => ({
          label: t(values.label),
          value: key,
          component: values.component,
        }));
    }

    const generateRandom7DigitId = () => {
      return Math.floor(Math.random() * (9999999+ 1));
    }

    const getDataByType = (dataArray: any, targetType: any) => {
      const result = dataArray.find((item: { type: any; }) => item.type === targetType);
      return result ? result.data : null;
    }

    const getTypeByKey = (logicalOperatorObjectArray: any, keyToFind: any) => {
      for (const obj of logicalOperatorObjectArray) {
        if (obj.data && obj.data[keyToFind]) {
          return obj.type;
        }
      }
      return null;
    }

    const initializeConfigs = () => {
      if (props.propertyDefinition.options) {
        typeProperty.value = 'boolean';
      } else if (props.propertyDefinition.logical_operators && Array.isArray(props.propertyDefinition.logical_operators)) {
        typeProperty.value = 'multipleGroupsLogicalOperators';
      } else if (props.propertyDefinition.logical_operators) {
        typeProperty.value = 'simpleGroupsLogicalOperators';
      }

      if (typeProperty.value === 'multipleGroupsLogicalOperators') {
        logicalOperatorsOptions.value = getGroupedMenuItems(props.propertyDefinition.logical_operators);
        configProperty.value.operator = configProperty.value.operator ?? logicalOperatorsOptions.value[0].items[0].value;
        configProperty.value.groupOperator = getTypeByKey(props.propertyDefinition.logical_operators, configProperty.value.operator);
        logicalOperatorDefinition.value = getDataByType(props.propertyDefinition.logical_operators, configProperty.value.groupOperator)[configProperty.value.operator];
        configProperty.value.data = configProperty.value.data ?? logicalOperatorDefinition.value.data;
      } else if (typeProperty.value === 'simpleGroupsLogicalOperators') {
        logicalOperatorsOptions.value = getStandardMenuItems(props.propertyDefinition.logical_operators);
        configProperty.value.operator = configProperty.value.operator ?? logicalOperatorsOptions.value[0].value;
        logicalOperatorDefinition.value = props.propertyDefinition.logical_operators[configProperty.value.operator];
        configProperty.value.data = configProperty.value.data ?? logicalOperatorDefinition.value.data;
      } else if (typeProperty.value === 'boolean') {
        configProperty.value.operator = 'boolean';
        configProperty.value.data = configProperty.value.data ?? props.propertyDefinition.data;
      }

      if (props.propertyDefinition.aggregate) {
        configProperty.value.aggregate = props.propertyDefinition.aggregate;
      }

      if (!configProperty.value.segmentId) {
        configProperty.value.segmentId = generateRandom7DigitId();
      }

      refreshKey.value += 1;
    };

    const onChangeLogicalOperator = () => {
      if (configProperty.value.operator) {
        const oldComponent = logicalOperatorDefinition.value.component;
        if (typeProperty.value === 'multipleGroupsLogicalOperators') {
          configProperty.value.groupOperator = getTypeByKey(props.propertyDefinition.logical_operators, configProperty.value.operator);
          logicalOperatorDefinition.value = getDataByType(props.propertyDefinition.logical_operators, configProperty.value.groupOperator)[configProperty.value.operator];
        } else {
          logicalOperatorDefinition.value = props.propertyDefinition.logical_operators[configProperty.value.operator];
        }
        const newComponent = logicalOperatorDefinition.value.component;

        // if the component has changed, we need to reset the data value
        if (oldComponent !== newComponent) {
          configProperty.value.data = logicalOperatorDefinition.value.data;
        }

        helpMessage.value = logicalOperatorDefinition.value.getHelpMessage  ?
          logicalOperatorDefinition.value.getHelpMessage(configProperty.value) : false;
      }
      refreshKey.value += 1;
    };

    watch(() => configProperty.value.data, () => {
      if (logicalOperatorDefinition.value) {
        helpMessage.value = logicalOperatorDefinition.value.getHelpMessage ?
          logicalOperatorDefinition.value.getHelpMessage(configProperty.value) : '';
      }
    }, { deep: true });

    const errorObj = ref<{ id: string; error: object } | undefined>({
      id: '',
      error: {},
    });
    const errors = ref();
    const fieldKey = computed(() => errorObj.value?.id);
    const fieldErrorKey = ref(0);
    watch(() => store.getters['segmentationsEditor/getErrors'],() => {
      const errorsArray = store.getters['segmentationsEditor/getErrors'];
      errors.value = null;
      errorsArray.segments.find((errorObject: any)=>{
        if (configProperty.value.segmentId == errorObject.segmentId) {
          errors.value = errorObject.value;
          fieldErrorKey.value += 1;
        }
      });
    }, {deep: true});

    const propertyValueInfo = computed(() => {
      if (logicalOperatorsOptions.value) {
        const propertyDefinition = t(props.propertyDefinition.label);

        let options = []

        if (logicalOperatorDefinition.value.props && logicalOperatorDefinition.value.props.options) {
          options = logicalOperatorDefinition.value.props.options();
        }

        if (logicalOperatorDefinition.value) {
          let currency = '';
          let previewValue = '';
          if (props.propertyDefinition.hasCurrency) {
            currency = UserState.activeShop?.currency ?? '';
            previewValue = logicalOperatorDefinition.value.getPreviewValue(configProperty.value.data, { currency });
          } else {
            previewValue = logicalOperatorDefinition.value.getPreviewValue(configProperty.value.data, options);
          }

          let label = `${propertyDefinition}`;
          if (logicalOperatorDefinition.value.beforeValueText) {
            label = `${label}: ${logicalOperatorDefinition.value.beforeValueText}`;
          }

          return `${label} ${t(logicalOperatorDefinition.value.label)} ${previewValue}`;
        }
      }
      if (typeProperty.value === 'boolean') {
        const propertyDefinition = t(props.propertyDefinition.label);
        const value = props.propertyDefinition.options.find((option: any) => option.value === configProperty.value.data);
        if (value !== null && value) {
          return `${propertyDefinition} : ${t(value.label)}`
        }
      }
      return ``;
    });

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);
    const showEditForm = computed(() => {
      if (!currentSegmentConfig.value) {
        return false;
      }
      return configProperty.value.segmentId === currentSegmentConfig.value.configProperty.segmentId;
    });

    const removeConfigProperty = () => {
      emit('remove-config-property');
    };

    const validateProperty = async () => {
      const { segmentId } = props.propertyConfig;
      const validation = await validateSegmentProperty(props.propertyConfig, props.propertyDefinition);
      if (validation && !validation.success) {
        store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
      } else {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
    };

    const editProperty = async () => {
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      store.commit('segmentationsEditor/setCurrentSegmentConfig', {
        configProperty: configProperty.value,
        propertyDefinition: props.propertyDefinition
      });
    };

    onMounted(() => {
      initializeConfigs();
    });

    return {
      t,
      typeProperty,
      configProperty,
      logicalOperatorsOptions,
      onChangeLogicalOperator,
      logicalOperatorDefinition,
      refreshKey,
      errors,
      fieldKey,
      fieldErrorKey,
      removeConfigProperty,
      validateProperty,
      propertyValueInfo,
      editProperty,
      showEditForm,
      helpMessage,
    };
  },
});
