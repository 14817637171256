// eslint-disable-next-line import/no-cycle
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioData,
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import useVuelidate from '@vuelidate/core';
import { nestGet } from '@/composables/nestApi';
// eslint-disable-next-line import/no-cycle
import { UserState } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import {
  checkShopsListImportStates,
} from '@/composables/mes-listes/ListsManage';
import { store } from '@/store';
import { verifyAndCountConfigSegmentation } from '@/composables/segmentationEditor';

export interface DeclencheurAudienceSetupData {
  lists: any[];
  segment_the_list: string;
  segmentation_config: any;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurAudienceSetupMetadata: ComponentMetadata<DeclencheurAudienceSetupData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxaudiencesetup',
    label: 'automatedScenarios.triggers.boxAudienceSetup.label',
    icon: 'fa-mail-bulk',
    hasConfiguration: true,
    component: 'DeclencheurAudienceSetup',
    incompatibilities: {
      bulk: {
        next_operators: [
          'boxaudiencesetup',
        ],
      },
    },
    availableInCampaign: [TypeCampaignEnum.BULK],
  },
  Create({
    lists,
    segment_the_list,
    segmentation_config,
  }: DeclencheurAudienceSetupData = {
    lists: [],
    segment_the_list: '0',
    segmentation_config: [],
  }): ComponentData<DeclencheurAudienceSetupData> {
    return {
      id: 'boxaudiencesetup',
      form_name: 'boxaudiencesetup',
      logo_box: 'fa-mail-bulk',
      lists,
      segment_the_list,
      segmentation_config,
    };
  },
  async PreValidate(data: DeclencheurAudienceSetupData): Promise<ErrorConfigForm> {
    let success = true;
    if (data.segment_the_list === '1') {
      await store.dispatch('segmentationsEditor/validate');
      const getHasError = store.getters['segmentationsEditor/getHasError'];
      success = !getHasError;
    }
    return {
      success,
    };
  },

  async Validate(data: DeclencheurAudienceSetupData): Promise<ErrorConfigForm> {
    const requiredSegConfig = data.segment_the_list === '1';
    const allRules: any = {
      lists: { required },
      segment_the_list: { required },
    };

    if (requiredSegConfig) {
      allRules.segmentation_config = { required };
    }
    const rules = computed(() => (allRules));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: DeclencheurAudienceSetupData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurAudienceSetupData): string {
    return translation.global.t('automatedScenarios.triggers.boxAudienceSetup.dynamicLabel', { number: data.lists.length });
  },

  async TargetBulkCalcul(scenarioData: AutomatedScenarioData, operatorData: DeclencheurAudienceSetupData, commercialCampaign: number): Promise<number|null> {
    const idShop = UserState.activeShop?.id ?? 0;
    // const dataQuery = JSON.stringify({
    //   idShop,
    //   lists: operatorData && operatorData.lists ? operatorData.lists : [],
    //   commercialCampaign,
    // });

    const result = await verifyAndCountConfigSegmentation(idShop, operatorData.segmentation_config);

    return result.totalContactsNumber;
  },

  async CheckImportState(scenarioData: AutomatedScenarioData, operatorData: DeclencheurAudienceSetupData): Promise<number> {
    const idShop = UserState.activeShop?.id ?? 0;

    const result = await checkShopsListImportStates(idShop, operatorData.lists);

    if (result && result.total) {
      return result.total;
    }

    return 0;
  },
};

export default DeclencheurAudienceSetupMetadata;
