
import {
  defineComponent, PropType, ref, SetupContext, watch,
} from 'vue';
import InputNumber from '@/components/automated-scenarios/fields/InputNumber.vue';
import { asFloat, asInt } from '@/helpers';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'SegmentGeographicalArea',

  components: {
    InputNumber,
    Dropdown,
  },

  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: any }, { emit }: SetupContext) {
    const radius = ref(asInt(props.modelValue.radius ?? 50));
    const latitude = ref(props.modelValue.latitude ? asFloat(props.modelValue.latitude) : 0);
    const longitude = ref(props.modelValue.longitude ? asFloat(props.modelValue.longitude) : 0);
    const unit = ref(props.modelValue.unit ?? 'km');
    const address = ref(props.modelValue.address ?? '');

    const units = ref([
      { label: 'km', value: 'km' },
      { label: 'mi', value: 'mi' },
    ]);

    const setPlace = (place: { geometry: { location: { lat: () => number; lng: () => number } }; formatted_address: string }) => {
      latitude.value = place.geometry.location.lat();
      longitude.value = place.geometry.location.lng();
      address.value = place.formatted_address;
    };

    watch([radius, latitude, longitude, unit, address], () => {
      emit('update:modelValue', {
        radius: radius.value,
        latitude: latitude.value,
        longitude: longitude.value,
        unit: unit.value,
        address: address.value,
      });
    });

    return {
      radius,
      unit,
      units,
      address,
      setPlace,
    };
  },
});
