/// actions
import ActionABTest from '@/components/automated-scenarios/forms/actions/ActionABTest.vue';
import ActionDelaisMax from '@/components/automated-scenarios/forms/actions/ActionDelaisMax.vue';
import ActionDisplayFreeModel from '@/components/automated-scenarios/forms/actions/ActionDisplayFreeModel.vue';
import ActionDisplayFreePopup from '@/components/automated-scenarios/forms/actions/ActionDisplayFreePopup.vue';
import ActionList from '@/components/automated-scenarios/forms/actions/ActionList.vue';
import ActionSendEmail from '@/components/automated-scenarios/forms/actions/ActionSendEmail.vue';
import ActionSendFbMessenger from '@/components/automated-scenarios/forms/actions/ActionSendFbMessenger.vue';
import ActionSendPushNotification from '@/components/automated-scenarios/forms/actions/ActionSendPushNotification.vue';
import ActionSendSms from '@/components/automated-scenarios/forms/actions/ActionSendSms.vue';
import ActionTag from '@/components/automated-scenarios/forms/actions/ActionTag.vue';
import ActionWait from '@/components/automated-scenarios/forms/actions/ActionWait.vue';

/// declencheurs
import DeclencheurAbandonPanier
  from '@/components/automated-scenarios/forms/declencheurs/DeclencheurAbandonPanier.vue';
import DeclencheurCollectAnonymousVisitor
  from '@/components/automated-scenarios/forms/declencheurs/DeclencheurCollectAnonymousVisitor.vue';
import DeclencheurCustomJS from '@/components/automated-scenarios/forms/declencheurs/DeclencheurCustomJS.vue';
import DeclencheurPeriodicLaunching
  from '@/components/automated-scenarios/forms/declencheurs/DeclencheurPeriodicLaunching.vue';
import DeclencheurQuitSite from '@/components/automated-scenarios/forms/declencheurs/DeclencheurQuitSite.vue';
import DeclencheurRegisterOnSite
  from '@/components/automated-scenarios/forms/declencheurs/DeclencheurRegisterOnSite.vue';
import DeclencheurScrollPage from '@/components/automated-scenarios/forms/declencheurs/DeclencheurScrollPage.vue';
import DeclencheurSitePassTime from '@/components/automated-scenarios/forms/declencheurs/DeclencheurSitePassTime.vue';
import DeclencheurStatusCommand
  from '@/components/automated-scenarios/forms/declencheurs/DeclencheurStatusCommand.vue';
import DeclencheurSubscribeToNewsletter
  from '@/components/automated-scenarios/forms/declencheurs/DeclencheurSubscribeToNewsletter.vue';
import DeclencheurVisitPage from '@/components/automated-scenarios/forms/declencheurs/DeclencheurVisitPage.vue';
import DeclencheurChooseBulkList from '@/components/automated-scenarios/forms/declencheurs/DeclencheurChooseBulkList.vue';

/// filtres
import FiltreActionOnCampaign from '@/components/automated-scenarios/forms/filtres/FiltreActionOnCampaign.vue';
import FiltreCurrentCart from '@/components/automated-scenarios/forms/filtres/FiltreCurrentCart.vue';
import FiltreCurrentOrder from '@/components/automated-scenarios/forms/filtres/FiltreCurrentOrder.vue';
import FiltreCustomer from '@/components/automated-scenarios/forms/filtres/FiltreCustomer.vue';
import FiltrePurchaseHistory from '@/components/automated-scenarios/forms/filtres/FiltrePurchaseHistory.vue';
import FiltreBoxNavigationTrigger from '@/components/automated-scenarios/forms/filtres/FiltreBoxNavigationTrigger.vue';
import FiltreClicklink from '@/components/automated-scenarios/forms/filtres/FiltreClicklink.vue';
import FiltreFilledoutForm from '@/components/automated-scenarios/forms/filtres/FiltreFilledoutForm.vue';
import FiltreOpenEmail from '@/components/automated-scenarios/forms/filtres/FiltreOpenEmail.vue';
import DeclencheurAudienceSetup from '@/components/automated-scenarios/forms/declencheurs/DeclencheurAudienceSetup.vue';
import FiltreContactSegmentation from '@/components/automated-scenarios/forms/filtres/FiltreContactSegmentation.vue';

const Components = {
  ActionABTest,
  ActionDelaisMax,
  ActionDisplayFreeModel,
  ActionDisplayFreePopup,
  ActionList,
  ActionSendEmail,
  ActionSendFbMessenger,
  ActionSendPushNotification,
  ActionSendSms,
  ActionTag,
  ActionWait,

  DeclencheurAbandonPanier,
  DeclencheurCollectAnonymousVisitor,
  DeclencheurCustomJS,
  DeclencheurPeriodicLaunching,
  DeclencheurQuitSite,
  DeclencheurRegisterOnSite,
  DeclencheurScrollPage,
  DeclencheurSitePassTime,
  DeclencheurStatusCommand,
  DeclencheurSubscribeToNewsletter,
  DeclencheurVisitPage,
  DeclencheurChooseBulkList,
  DeclencheurAudienceSetup,

  FiltreActionOnCampaign,
  FiltreCurrentCart,
  FiltreCurrentOrder,
  FiltreCustomer,
  FiltrePurchaseHistory,
  FiltreBoxNavigationTrigger,
  FiltreClicklink,
  FiltreFilledoutForm,
  FiltreOpenEmail,
  FiltreContactSegmentation,
};

export default Components;
