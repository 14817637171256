<template>
  <Accordion
    :key="updateSpmAccordionKey"
    v-model:activeIndex="activePanel"
    multiple
    class="mb-2 spm-panel-accordion"
  >
    <AccordionTab
      v-for="panel in panels.filter((item) => !item.hide)"
      :key="panel.id"
    >
      <template #header>
        <span class="flex align-items-center gap-2 w-full">
          {{ t(panel.title) }}
        </span>
      </template>
      <slot :name="panel.id" />
    </AccordionTab>
  </Accordion>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount,
  PropType, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export interface SpmPanelAccordionInterface {
  id: string;
  title: string;
  hide?: boolean;
}

export default defineComponent({
  name: 'PanelAccordion',

  components: {
    Accordion,
    AccordionTab,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    // Array of panels to display
    panels: {
      type: Array as PropType<SpmPanelAccordionInterface[]>,
      required: true,
    },

    // If true, always keep one panel open
    alwaysKeepOneOpen: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  setup(props: { panels: object[]; alwaysKeepOneOpen: boolean }) {
    const { t } = useI18n();
    const activePanel = ref<number[]>([0]);
    const updateSpmAccordionKey = ref(0);

    // If alwaysKeepOneOpen is true, watch activePanel and prevent it from being null
    if (props.alwaysKeepOneOpen) {
      watch(activePanel, (newValue: number[], oldValue: number[]) => {
        if (newValue.length === 0) {
          activePanel.value = oldValue.length === 0 ? [0] : oldValue;
          updateSpmAccordionKey.value += 1;
        }
      });
    }

    onBeforeMount(() => {
      // all panels are open by default
      activePanel.value = props.panels.map((_, index) => index);
    });

    return {
      activePanel,
      updateSpmAccordionKey,
      t,
    };
  },
});
</script>

<style lang="scss">
.spm-panel-accordion {
    margin-bottom: 1rem !important;
  .p-accordion-tab {
    margin-bottom: 0.5rem !important;
    border: none;
    .p-accordion-header {
      border: 1px solid #dee2e6;
      border-radius: 4px;
      .p-accordion-header-link {
        border: none !important;
        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .p-accordion-content {
      border: none !important;
      padding: 0.5rem !important;
    }
  }

}
</style>
