
import {
  computed,
  defineComponent, watch, ref, PropType, SetupContext, onBeforeUnmount, onMounted,
} from 'vue';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Skeleton from 'primevue/skeleton';
import SegmentationBlockAND from '@/components/segmentations/SegmentationBlockAND.vue';
import { useI18n } from 'vue-i18n';
import { store } from '@/store';
import Loader from '@/components/layout/Loader.vue';
import validateSegmentProperty from '@/components/segmentations/utils/utils';
import axios from 'axios';
import { UserState } from '@/composables/User';
import { showToastError, showToastSuccess } from '@/helpers';
import { formatNumbersInString } from '@/helpers/Number';
import SegmentationPromptAI from '@/components/segmentations/SegmentationPromptAI.vue';

export default defineComponent({
  name: 'SegmentationsPanel',
  components: {
    SegmentationPromptAI,
    Loader,
    SegmentationBlockAND,
    Button,
    Tag,
    Skeleton,
  },

  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({}),
    },

    origin: {
      type: String,
      required: false,
      default: '',
    },

    size: {
      type: String,
      required: false,
      default: '',
    },

    showPromptAI: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props: {
    modelValue: any;
    origin: string;
    size: string;
    showPromptAI: boolean;
  }, { emit }: SetupContext) {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;
    const cleanedDataKey = ref(0);
    const AIGenerationInProgress = ref(false);
    const segmentsConfiguration: any = computed(() => store.getters['segmentationsEditor/getConfiguration']);
    const listImportId: any = computed(() => store.getters['segmentationsEditor/getShopListImportId']);

    const refreshComponents: any = computed(() => store.getters['segmentationsEditor/getRefreshComponents']);

    const getInitialized: any = computed(() => store.getters['segmentationsEditor/getInitialized']);

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const refreshContactsNumber = computed(() => store.getters['segmentationsEditor/getRefreshContactsNumber']);

    const loadContactsNumber = ref(false);

    const totalContactsNumber: any = ref(0);

    const addBlockAND = async () => {
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', {
            type: 'segments',
            value: { segmentId, value: validation.validate.value },
          });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      store.commit('segmentationsEditor/addBlockAND');
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      return true;
    };

    const cleanData = (data: any) => {
      const cleanedData = data.map((subArray: any) => subArray.filter((obj: any) => Object.keys(obj).length !== 0));
      return cleanedData.filter((subArray: any) => subArray.length > 0);
    };

    const handleContactsNumber = async () => {
      loadContactsNumber.value = true;
      const tmpCleanedData = cleanData(segmentsConfiguration.value);
      try {
        const result = await axios.post(
          `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/count`,
          {
            idShop,
            config: tmpCleanedData,
          },
        );

        if (result && (result.status === 200 || result.status === 201)) {
          if (result.data.error) {
            totalContactsNumber.value = null;
          } else {
            totalContactsNumber.value = result.data;
          }
        } else {
          totalContactsNumber.value = 0;
          await showToastError('GENERIC_ERROR');
        }
      } catch (e) {
        totalContactsNumber.value = 0;
        await showToastError('GENERIC_ERROR');
      }

      loadContactsNumber.value = false;
      store.commit('segmentationsEditor/setRefreshContactsNumber', false);
    };

    const handleGenerationByAI = async (generatedConfig: any) => {
      if (generatedConfig.success === false) {
        AIGenerationInProgress.value = false;
        await showToastError(t('segmentations.promptAI.error'));
        return;
      }

      store.commit('segmentationsEditor/setConfiguration', generatedConfig.config);
      await handleContactsNumber();
      AIGenerationInProgress.value = false;
      await showToastSuccess(t('segmentations.promptAI.success'));
    };

    const moveBlockAndToBlockOr = (index: any) => {
      store.commit('segmentationsEditor/moveBlockAndToBlockOr', index);
      handleContactsNumber();
    };

    watch([segmentsConfiguration], () => {
      if (!segmentsConfiguration.value.length || !segmentsConfiguration.value) {
        store.commit('segmentationsEditor/initConfiguration');
      }
      emit('update:modelValue', segmentsConfiguration.value);
    }, { deep: true });

    watch([refreshContactsNumber], async () => {
      // TODO : only if comming from import page
      if (refreshContactsNumber.value) {
        await handleContactsNumber();
      }
    });

    onMounted(async () => {
      const dataToUse = props.origin === 'list' ? segmentsConfiguration.value : props.modelValue;
      await store.dispatch('segmentationsEditor/initialization',
        { originCall: props.origin, configSegmentation: dataToUse, listImportId: listImportId.value ?? 0 });
      // await handleContactsNumber();
    });

    onBeforeUnmount(() => {
      store.commit('segmentationsEditor/resetState');
    });

    return {
      t,
      cleanedDataKey,
      getInitialized,
      refreshComponents,
      segmentsConfiguration,
      loadContactsNumber,
      totalContactsNumber,
      handleContactsNumber,
      handleGenerationByAI,
      AIGenerationInProgress,
      moveBlockAndToBlockOr,
    };
  },

  methods: { formatNumbersInString },
});
