// eslint-disable-next-line import/no-cycle
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import {
  ShopsTags,
  ShopsTagsInputItem,
  ShopsTagsUpdateInputItem,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import { InsertShopsTags } from '@/composables/shop/ShopsTags';
import { computed } from 'vue';
import { between, integer, required } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';

export interface ActionTagData {
  autocomp_tag: ShopsTags[];
  multiplebox_type: number; // 0 ajouter, 1 retirer
}

const ActionTagMetadata: ComponentMetadata<ActionTagData> = {
  Meta: {
    kind: 'action',
    id: 'boxtag',
    label: 'Tag',
    icon: 'fa-tag',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching',
        ],
      },
      bulk: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxchoosebulklist', 'boxaudiencesetup',
        ],
      },
    },
    component: 'ActionTag',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
  },
  Create({
    autocomp_tag,
    multiplebox_type,
  }: ActionTagData = {
    autocomp_tag: [],
    multiplebox_type: 0,
  }): ComponentData<ActionTagData> {
    return {
      id: 'boxtag',
      form_name: 'boxtag',
      logo_box: 'fa-anchor',
      autocomp_tag,
      multiplebox_type,
    };
  },
  async Validate(data: ActionTagData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      autocomp_tag: {
        required,
      },
      multiplebox_type: {
        required,
        includesValuesValidator: includesValuesValidator([0, 1]),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: ActionTagData): number {
    return 1;
  },
  Label(data: ActionTagData): string {
    return 'Tag';
  },
  BeforeInsertData(data: ActionTagData): ActionTagData {
    let dataToReturn: ActionTagData = data;
    if (data.autocomp_tag.length > 0) {
      const formatData = data.autocomp_tag.reduce((accumulator: any, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[currentValue.id_shop_tag] = currentValue.tag;
        return accumulator;
      }, {});
      dataToReturn = {
        ...dataToReturn,
        autocomp_tag: formatData,
      };
    }
    return dataToReturn;
  },
};

export default ActionTagMetadata;
