import axios from 'axios';

export const cleanData = (data: any) => {
  const cleanedData = data.map((subArray: any) => subArray.filter((obj: any) => Object.keys(obj).length !== 0));
  return cleanedData.filter((subArray: any) => subArray.length > 0);
};

export const verifyAndCountConfigSegmentation = async (idShop: number, config: any) => {
  const tmpCleanedConfig = cleanData(config);
  const $return = {
    isValid: false,
    totalContactsNumber: 0,
  };

  try {
    const result = await axios.post(
      `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/count`,
      {
        idShop,
        config: tmpCleanedConfig,
      },
    );

    if (result && (result.status === 200 || result.status === 201)) {
      $return.isValid = true;
      $return.totalContactsNumber = result.data;
    } else {
      $return.isValid = false;
    }
  } catch (e) {
    $return.isValid = false;
  }

  return $return;
};
