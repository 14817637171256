// eslint-disable-next-line import/no-cycle
import {
  selectionBasedLogicalOperators,
  numberLogicalOperators,
  stringLogicalOperators, dateStringLogicalOperators, dateNumberLogicalOperators, selectionBasedIDLogicalOperators,
} from '@/composables/segmentations/logicalOperators';
// eslint-disable-next-line import/no-cycle
import {
  getProductOrdersPropertiesSegments,
} from '@/composables/segmentations/themes/common-segments/productPropertiesSegment';
// eslint-disable-next-line import/no-cycle
import { fetchOrderStatus } from '@/composables/segmentations/helpers/fetchData';

/**
 * Get the carrier properties segments
 */
const getCarrierPropertiesSegments = async () => [
  {
    id: 'name',
    label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.carriers.subProperties.carrierName',
    logical_operators: stringLogicalOperators('name'),
  },
  {
    id: 'id_carrier',
    label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.carriers.subProperties.idCarrier',
    logical_operators: selectionBasedIDLogicalOperators('id_carrier'),
  },
];

/**
 * Get the order properties segments
 */
const getOrderPropertiesSegments = async () => {
  const orderStatus = await fetchOrderStatus();

  const productProperties = await getProductOrdersPropertiesSegments();
  const carrierProperties = await getCarrierPropertiesSegments();
  return [
    {
      id: 'status',
      label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.list.status',
      logical_operators: selectionBasedLogicalOperators('status', orderStatus),
    },
    {
      id: 'amount',
      label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.list.amount',
      logical_operators: numberLogicalOperators('amount'),
      hasCurrency: true,
    },
    {
      id: 'amount_without_tax',
      label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.list.amount_without_tax',
      logical_operators: numberLogicalOperators('amount_without_tax'),
      hasCurrency: true,
    },
    {
      id: 'voucher_amount',
      label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.list.voucher_amount',
      logical_operators: numberLogicalOperators('voucher_amount'),
      hasCurrency: true,
    },
    {
      id: 'voucher_code',
      label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.list.voucher_code',
      logical_operators: stringLogicalOperators('voucher_code'),
    },
    {
      id: 'is_valid',
      label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.list.is_valid',
      options: [
        { label: 'yes', value: true },
        { label: 'no', value: false },
      ],
      data: true,
    },
    {
      id: 'carriers',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.carriers.title',
      sub_properties: carrierProperties,
    },
    {
      id: 'products_in_order',
      multiple: true,
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.title',
      sub_properties: productProperties,
    },
    {
      id: 'date',
      label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.list.date_creation',
      logical_operators: [
        {
          type: 'dateString',
          data: dateStringLogicalOperators('date'),
        },
        {
          type: 'dateNumber',
          data: dateNumberLogicalOperators('date'),
        },
      ],
    },
    {
      id: 'date_modification',
      label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.list.date_modification',
      logical_operators: [
        {
          type: 'dateString',
          data: dateStringLogicalOperators('date_modification'),
        },
        {
          type: 'dateNumber',
          data: dateNumberLogicalOperators('date_modification'),
        },
      ],
    },
  ];
};

export default getOrderPropertiesSegments;
