
import {
  defineComponent,
  onUpdated,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import AutoComplete from 'primevue/autocomplete';
import { DomHandler } from 'primevue/utils';

export default defineComponent({
  name: 'SegmentAutoComplete',

  components: {
    AutoComplete,
  },

  props: {
    modelValue: {
      type: Object as PropType<string[]>,
      required: true,
    },

    options: {
      type: Function,
      required: true,
    },

    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },

    field: {
      type: String,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: string[]; options: Function }, { emit }: SetupContext) {
    const filteredItems = ref();
    const inputs: Ref<string[]> = ref(props.modelValue ?? []);

    const handleComplete = ({ query }: { query: string }) => {
      if (!inputs.value.includes(query)) {
        filteredItems.value = [query];
      } else {
        filteredItems.value = [];
      }
    };

    watch(inputs, () => {
      emit('update:modelValue', inputs.value);
    });

    // todo: have to recheck this
    // Bug in primevue autoHighlight functionality, select first element manually
    onUpdated(() => {
      const firstItem: HTMLElement = document.querySelector('.p-autocomplete-items > li') as HTMLElement;
      if (firstItem) {
        DomHandler.addClass(firstItem, 'p-highlight');
      }
    });

    return {
      filteredItems,
      inputs,
      handleComplete,
    };
  },
});
