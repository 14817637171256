
import {
  defineComponent, onBeforeMount,
  PropType, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export interface SpmPanelAccordionInterface {
  id: string;
  title: string;
  hide?: boolean;
}

export default defineComponent({
  name: 'PanelAccordion',

  components: {
    Accordion,
    AccordionTab,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    // Array of panels to display
    panels: {
      type: Array as PropType<SpmPanelAccordionInterface[]>,
      required: true,
    },

    // If true, always keep one panel open
    alwaysKeepOneOpen: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  setup(props: { panels: object[]; alwaysKeepOneOpen: boolean }) {
    const { t } = useI18n();
    const activePanel = ref<number[]>([0]);
    const updateSpmAccordionKey = ref(0);

    // If alwaysKeepOneOpen is true, watch activePanel and prevent it from being null
    if (props.alwaysKeepOneOpen) {
      watch(activePanel, (newValue: number[], oldValue: number[]) => {
        if (newValue.length === 0) {
          activePanel.value = oldValue.length === 0 ? [0] : oldValue;
          updateSpmAccordionKey.value += 1;
        }
      });
    }

    onBeforeMount(() => {
      // all panels are open by default
      activePanel.value = props.panels.map((_, index) => index);
    });

    return {
      activePanel,
      updateSpmAccordionKey,
      t,
    };
  },
});
