// eslint-disable-next-line import/no-cycle
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioData,
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import useVuelidate from '@vuelidate/core';
import { nestGet } from '@/composables/nestApi';
// eslint-disable-next-line import/no-cycle
import { UserState } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import {
  checkShopsListImportStates
} from '@/composables/mes-listes/ListsManage';

export interface DeclencheurChooseBulkListData {
  lists: any[];
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurChooseBulkListMetadata: ComponentMetadata<DeclencheurChooseBulkListData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxchoosebulklist',
    label: 'automatedScenarios.triggers.boxChooseBulkList.label',
    icon: 'fa-mail-bulk',
    hasConfiguration: true,
    incompatibilities: {
      bulk: {
        next_operators: [
          'boxchoosebulklist',
        ],
      },
    },
    component: 'DeclencheurChooseBulkList',
    availableInCampaign: [],
  },
  Create({
    lists,
  }: DeclencheurChooseBulkListData = {
    lists: ['NY'],
  }): ComponentData<DeclencheurChooseBulkListData> {
    return {
      id: 'boxchoosebulklist',
      form_name: 'boxchoosebulklist',
      logo_box: 'fa-mail-bulk',
      lists,
    };
  },
  async Validate(data: DeclencheurChooseBulkListData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      lists: {
        required,
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: DeclencheurChooseBulkListData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurChooseBulkListData): string {
    return translation.global.t('automatedScenarios.triggers.boxChooseBulkList.dynamicLabel', { number: data.lists.length });
  },

  async TargetBulkCalcul(scenarioData: AutomatedScenarioData, operatorData: DeclencheurChooseBulkListData, commercialCampaign: number): Promise<number|null> {
    const idShop = UserState.activeShop?.id ?? 0;
    const dataQuery = JSON.stringify({
      idShop,
      lists: operatorData && operatorData.lists ? operatorData.lists : [],
      commercialCampaign,
    });

    const result = await nestGet('v4', '/workflow/getBulkTarget', {}, `?data=${dataQuery}`);

    if (result && result.success) {
      return result.count;
    }

    return null;
  },

  async CheckImportState(scenarioData: AutomatedScenarioData, operatorData: DeclencheurChooseBulkListData): Promise<number> {
    const idShop = UserState.activeShop?.id ?? 0;

    const result = await checkShopsListImportStates(idShop, operatorData.lists);

    if (result && result.total) {
      return result.total;
    }

    return 0;
  },
};

export default DeclencheurChooseBulkListMetadata;
