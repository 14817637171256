
import {
  computed,
  defineComponent, onMounted, ref, SetupContext, watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import SegmentationSimpleProperty from "@/components/segmentations/SegmentationSimpleProperty.vue";
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import PropertyDeletePanel from '@/components/segmentations/PropertyDeletePanel.vue';
import { useI18n } from "vue-i18n";
import { store } from "@/store";
import validateSegmentProperty from '@/components/segmentations/utils/utils';
import SegmentationComplexPropertyDropdown from '@/components/segmentations/SegmentationComplexPropertyDropdown.vue';

export default defineComponent({
  name: 'SegmentationComplexProperty',

  components: {
    Dropdown,
    InputText,
    SegmentationSimpleProperty,
    SpmButton,
    SpmOverlayPanel,
    PropertyDeletePanel,
    SegmentationComplexPropertyDropdown,
  },

  props: {
    propertyConfig: {
      type: Object,
      required: true,
    },

    propertyDefinition: {
      type: Object,
      required: true,
    },

    level: {
      type: Number,
      required: true,
    },

    size: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['refreshComponent'],


  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const configProperty = ref(props.propertyConfig);
    const definitionProperty = ref(props.propertyDefinition);

    const subPropertiesOptions: any = ref(Object.entries(definitionProperty.value.sub_properties).map(([key, values]: [string, any]) => {
      return {
        label: t(values.label),
        value: values.id,
      };
    }));

    const subPropertiesDefinitions = computed(() => {
      return definitionProperty.value.sub_properties.reduce((acc: any, item: any) => {
        acc[item.id] = item;
        return acc;
      }, {});
    });

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const showEditForm = (subProperty: any) => {
      if (!subProperty.id || (currentSegmentConfig.value && currentSegmentConfig.value.configProperty.segmentId === subProperty.segmentId) || (subProperty.sub_properties)) {
        return true;
      }
      return false;
    };

    const addEmptyConfigSimpleProperty = async (verifyCurrent = false) => {
      if (!verifyCurrent) {
        configProperty.value.sub_properties.push({
          id: "",
        });
        return true;
      }
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      configProperty.value.sub_properties.push({
        id: "",
      });
    }

    const removeConfigProperty = (index: number) => {
      const checkCurrentSegmentConfig = (properties: any[]) => {
        if (currentSegmentConfig.value) {
          properties.forEach((property) => {
            if (property.sub_properties) {
              checkCurrentSegmentConfig(property.sub_properties);
            } else if (property.segmentId === currentSegmentConfig.value.configProperty.segmentId) {
              store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
            }
          });
        }
      }

      if (currentSegmentConfig.value) {
        checkCurrentSegmentConfig(configProperty.value.sub_properties);
      }
      configProperty.value.sub_properties.splice(index,1);
      if(configProperty.value.sub_properties.length === 0){
        addEmptyConfigSimpleProperty();
      }
      store.commit('segmentationsEditor/setRefreshContactsNumber', true);
    };

    const refreshComplexPropertyComponent = () => {
      emit('refreshComponent');
    }

    const resetPropertyInput = (index: number, subPropertyId: any, subPropertyDef: any) => {
      if (subPropertyDef.sub_properties){
        configProperty.value.sub_properties[index] = {
          id: subPropertyId,
          sub_properties: [
            {
              id: ""
            }
          ]
        };
      } else {
        configProperty.value.sub_properties[index] = {
          id: subPropertyId
        };
      }

      if (subPropertyDef && !subPropertyDef.sub_properties) {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', {
          configProperty: configProperty.value.sub_properties[index],
          propertyDefinition: subPropertyDef,
        });
      }
      if (subPropertyDef.sub_properties) {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      }

      refreshComplexPropertyComponent();
    }

    const dropdownRefs = ref<any[]>([]);
    const onBeforeShow = async (index: number) => {
      if (currentSegmentConfig.value) {
        if (currentSegmentConfig.value.configProperty.segmentId !== configProperty.value.sub_properties[index].segmentId) {
          const { segmentId } = currentSegmentConfig.value.configProperty;
          const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
          if (validation && !validation.success) {
            store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
            dropdownRefs.value[index].hide();
            return false;
          }
          store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
          store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
          store.commit('segmentationsEditor/setRefreshContactsNumber', true);
        }
      } else {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      }
    };

    onMounted(() => {
      // if no sub properties on config, add one
      if(configProperty.value.sub_properties.length === 0){
        addEmptyConfigSimpleProperty();
      }
    });

    return {
      t,
      configProperty,
      subPropertiesDefinitions,
      subPropertiesOptions,
      dropdownRefs,
      definitionProperty,
      addEmptyConfigSimpleProperty,
      removeConfigProperty,
      resetPropertyInput,
      refreshComplexPropertyComponent,
      onBeforeShow,
      showEditForm,
    };
  },
});
