
import {
  defineComponent, ref,
} from 'vue';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import axios from 'axios';
import { GetReadableShopDefaultLang, MakeSPMAIRequest } from '@/composables/services/ShopimindAI';
import sha1 from 'sha1';
import { UserState } from '@/composables/User';

export default defineComponent({
  name: 'SegmentationPromptAI',

  components: {
    Textarea,
    Button,
  },

  directives: {
    tooltip: Tooltip,
  },

  emits: ['generate-config', 'generate-config-in-progress'],

  setup(_, { emit }) {
    const { t } = useI18n();
    const idShop = ref(UserState?.activeShop?.id ?? 0);

    const promptText = ref('');
    const isLoadingGeneration = ref(false);
    const feedback = ref<string | null>(null);
    const showFeedbackIcons = ref(false);
    const lastAIResponse: any = ref({
      id: null,
      question: null,
      response: null,
    });
    const lastFeedbackID = ref(null);

    // eslint-disable-next-line no-shadow
    const sendMessageToSlack = async (channelKey: string, promptText: string, configData: any) => {
      try {
        axios.post(
          `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/monitoring`,
          {
            channelKey,
            promptText,
            configData,
          },
        );
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    };

    const setFeedback = (type: 'like' | 'dislike') => {
      if (lastAIResponse.value.id === lastFeedbackID.value) {
        return;
      }
      feedback.value = type;
      lastFeedbackID.value = lastAIResponse.value.id;

      if (type === 'like') {
        sendMessageToSlack('B08GK97AQG2/AE14VoG8pFEukgLUhp45xQj4', lastAIResponse.value.question, lastAIResponse.value.response);
      } else {
        sendMessageToSlack('B08GMRMKMFW/KA4xXavygvmGsIokhzqHtcME', lastAIResponse.value.question, lastAIResponse.value.response);
      }
      // TODO : call API to give feedback
    };

    const generateConfig = async () => {
      emit('generate-config-in-progress');
      isLoadingGeneration.value = true;
      feedback.value = null;
      showFeedbackIcons.value = false;

      // sleep 2 seconds for test loading
      // TODO : call API to generate config
      const aiRequestPromise = await MakeSPMAIRequest(
        `config-generator/get-segmentation-from-prompt/${idShop.value}/${sha1(`${process.env.VUE_APP_SPMAI_API_SALT}${idShop.value}`)}`,
        {
          prompt: promptText.value,
        },
      );
      const configGen = aiRequestPromise?.data?.result?.content ?? null;

      let success = true;
      let generatedConfig: any = null;
      if (!configGen || configGen === 'FALSE') {
        success = false;
      } else {
        generatedConfig = JSON.parse(configGen);
      }

      if (success) {
        try {
          const result = await axios.post(
            `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/validate`,
            {
              idShop: idShop.value,
              config: generatedConfig,
            },
          );
          if (!result || (result.status !== 200 && result.status !== 201)) {
            success = false;
          }
        } catch (error) {
          success = false;
        }
      }

      isLoadingGeneration.value = false;
      emit('generate-config', {
        success,
        config: generatedConfig,
      });
      if (success) {
        sendMessageToSlack('B08GG4UCJNR/9RrQf4UmVikP0wAYhUN1Yn1y', promptText.value, generatedConfig);
        lastAIResponse.value = {
          id: Math.random(),
          question: promptText.value,
          response: generatedConfig,
        };
        showFeedbackIcons.value = true;
        // eslint-disable-next-line no-empty
      } else {
        sendMessageToSlack('B08H7DEGTCY/SPDhnZIoUBSoOUxnVGtfZobC', promptText.value, generatedConfig);
      }
    };

    return {
      t,
      promptText,
      generateConfig,
      isLoadingGeneration,
      feedback,
      setFeedback,
      showFeedbackIcons,
    };
  },
});
