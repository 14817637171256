// eslint-disable-next-line import/no-cycle
import { StringMap, TypeCampaignEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, NumberRange, Period, PickerData,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { decreaseNumberOfBranchesOfAnOperator } from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import {
  integer, maxLength, minLength, required, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { atLeastOneSegmentValidator, includesValuesValidator } from '@/helpers/CustomValidator';
import { store } from '@/store';

export interface FiltreContactSegmentationData {
  custom_title: string;
  custom_description: string;
  outputs_number: number;
  initial_number_outputs?: null|number; // for updating outputs
  grant_additional_delay: string;
  extra_time: Period;
  segmentation_config: any;
}

const FiltreContactSegmentationMetadata: ComponentMetadata<FiltreContactSegmentationData> = {
  Meta: {
    kind: 'filtre',
    id: 'boxcontactseg',
    label: 'Conditions logiques',
    icon: 'fa-filter',
    hasConfiguration: true,
    component: 'FiltreContactSegmentation',
    availableInCampaign: [TypeCampaignEnum.BULK],
  },
  Create(data: FiltreContactSegmentationData = {
    custom_title: '',
    custom_description: '',
    outputs_number: 1,
    grant_additional_delay: '0',
    extra_time: {
      value: 1,
      unit: 'DAY',
    },
    segmentation_config: [],
  }): ComponentData<FiltreContactSegmentationData> {
    return {
      id: 'boxcontactseg',
      form_name: 'boxcontactseg',
      logo_box: 'fa-cogs',
      ...data,
    };
  },
  async PreValidate(data: FiltreContactSegmentationData): Promise<ErrorConfigForm> {
    await store.dispatch('segmentationsEditor/validate');
    const getHasError = store.getters['segmentationsEditor/getHasError'];
    return {
      success: !getHasError,
    };
  },
  async Validate(data: FiltreContactSegmentationData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      custom_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      outputs_number: {
        required,
        includesValuesValidator: includesValuesValidator([1, 2]),
      },
      grant_additional_delay: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
      extra_time: {
        value: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          integer,
        },
        unit: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          includesValuesValidator: includesValuesValidator(['SECOND', 'MINUTE', 'HOUR', 'DAY']),
        },
      },
      segmentation_config: { required },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  BeforeInsertData(data: FiltreContactSegmentationData): FiltreContactSegmentationData {
    const dataToReturn: FiltreContactSegmentationData = data;
    const { initial_number_outputs } = dataToReturn;

    if (typeof dataToReturn.initial_number_outputs !== 'undefined') {
      delete dataToReturn.initial_number_outputs;
    }

    if (initial_number_outputs !== null && initial_number_outputs !== undefined
      && data.outputs_number < initial_number_outputs) {
      /* En cas de dimunition du nombre de branches, on supprime la branche "non" */
      decreaseNumberOfBranchesOfAnOperator(['output_2']);
    }

    return dataToReturn;
  },
  Outputs(data: FiltreContactSegmentationData): number {
    return data.outputs_number;
  },
  Label(data: FiltreContactSegmentationData): string {
    return data.custom_title;
  },
};

export default FiltreContactSegmentationMetadata;
