<template>
  <div v-if="!configuringSegment">
    <Message
      severity="warn"
      :closable="false"
    >
      Ce filtre sera bientôt supprimé et remplacé par le filtre "Conditions logiques", déjà disponible.
      Nous vous recommandons d’adopter ce nouveau filtre dès maintenant, avant la désactivation des anciens filtres prévue le 31/03/2025.
    </Message>
    <FilterGlobalInformations
      v-model="commonFields"
      v-model:form-validation="formValidationErrors"
    />

    <div class="field-group pt-2">
      <div class="field-group-content">
        <div class="field-wrapper">
          <ButtonGroup
            v-model="relevantPeriod"
            :options="periods"
            title="automatedScenarios.fields.relevantPeriod"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="period_concerned"
          />
        </div>
      </div>
    </div>

    <div
      v-if="relevantPeriod === '1'"
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <PeriodSelector
              v-model="period"
              :units="units"
              title="automatedScenarios.fields.duration_2"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="period_time"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <FilterPanelContent
    :active-items="activeItemsFilterPanel"
    :panels="['segments']"
  >
    <template #segments>
      <FilterSegmentForm
        :segments="segments"
        :active-segments="activeSegments"
        :items="filters"
        @add:segment="handleSaveSegment"
        @remove:segment="({ segmentName }) => handleRemoveSegment(segmentName)"
      />
      <div
        v-if="hasActiveSegments()"
        class="field-wrapper mt-3"
      >
        <ButtonGroup
          v-model="segmentOperator"
          :options="logicalOperators"
          title="automatedScenarios.fields.visitorMustValidate"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="operatorBoxNav"
        />
      </div>
    </template>
  </FilterPanelContent>
  <FieldErrors
    :key="componentFieldErrorsKey"
    :errors="formValidationErrors"
    field="products"
  />
</template>

<script lang="ts">
import FiltreBoxNavigationTriggerMetaData, {
  FiltreBoxNavigationTriggerData,
  FiltreBoxNavigationTriggerFilters,
} from '@/components/automated-scenarios/metadata/filtres/FiltreBoxNavigationTriggerMetadata';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import {
  computed, defineComponent, onMounted,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import { FilterField, FilterSegment, Period } from '@/types/automated-scenarios';
import { ActiveSegment } from '@/composables/AutomatedScenarioSegments';
import { StringMap } from '@/types';
import { AutomatedScenarioState as state } from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { asInt } from '@/helpers';
import Message from 'primevue/message';
import FilterGlobalInformations from '../../fields/FilterGlobalInformations.vue';
import FilterSegmentForm from '../../FilterSegmentForm.vue';
import FilterPanelContent from '../../FilterPanelContent.vue';
import getSegments from './segments/FiltreBoxNavigationTriggerSegments';

export default defineComponent({
  name: 'FiltreBoxNavigationTrigger',
  components: {
    FieldErrors,
    FilterGlobalInformations,
    ButtonGroup,
    PeriodSelector,
    FilterSegmentForm,
    FilterPanelContent,
    Message,
  },

  props: {
    modelValue: {
      type: Object as PropType<FiltreBoxNavigationTriggerData>,
      required: true,
      default() {
        return FiltreBoxNavigationTriggerMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: FiltreBoxNavigationTriggerData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const scenarioType = ref(state.scenario.type);

    const commonFields = ref({
      custom_title: props.modelValue.custom_title,
      custom_description: props.modelValue.custom_description,
      outputs_number: asInt(props.modelValue.outputs_number),
    });
    const relevantPeriod = ref(props.modelValue.period_concerned);
    const segmentOperator = ref(props.modelValue.operatorBoxNav);
    const period = ref<Period>({
      unit: props.modelValue.period_time ? props.modelValue.period_time.unit : 'MINUTE',
      value: props.modelValue.period_time ? props.modelValue.period_time.value : 1,
    });
    const segments: Ref<FilterSegment<FiltreBoxNavigationTriggerFilters>[]> = computed(() => getSegments());

    const activeSegments = computed(() => {
      const active: ActiveSegment[] = [];
      if (Object.keys(props.modelValue.products).length > 0) {
        active.push({ name: 'products', label: segments.value.filter((segment) => segment.id === 'products')[0].option.label, show: true });
      }
      if (Object.keys(props.modelValue.categories).length > 0) {
        active.push({ name: 'categories', label: segments.value.filter((segment) => segment.id === 'categories')[0].option.label, show: true });
      }
      if (Object.keys(props.modelValue.manufacturers).length > 0) {
        active.push({ name: 'manufacturers', label: segments.value.filter((segment) => segment.id === 'manufacturers')[0].option.label, show: true });
      }
      if (Object.keys(props.modelValue.urls).length > 0) {
        active.push({ name: 'urls', label: segments.value.filter((segment) => segment.id === 'urls')[0].option.label, show: true });
      }
      return active;
    });

    const configuringSegment = computed(() => state.configuringSegment);

    const activeItemsFilterPanel = computed(() => ({ segments: activeSegments.value.length }));

    const units = [
      { value: 'SECOND', label: t('automatedScenarios.fields.durations.second(s)') },
      { value: 'MINUTE', label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: 'HOUR', label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: 'DAY', label: t('automatedScenarios.fields.durations.day(s)') },
    ];
    const periods = [
      { value: '0', label: t('automatedScenarios.fields.periods.lastVisit') },
      { value: '1', label: t('automatedScenarios.fields.periods.definedPeriod') },
    ];
    const logicalOperators = [
      { value: 'and', label: t('automatedScenarios.fields.allSegments') },
      { value: 'or', label: t('automatedScenarios.fields.atLeastOne') },
    ];

    const filters: Ref<FiltreBoxNavigationTriggerFilters> = ref({
      products: props.modelValue.products,
      categories: props.modelValue.categories,
      manufacturers: props.modelValue.manufacturers,
      urls: props.modelValue.urls,
    });

    const setPickerSegment = (segment: FilterSegment<FiltreBoxNavigationTriggerFilters>) => {
      const number = segment.fields[0];
      const operator = segment.fields[1];
      const picker = segment.fields[2];
      const value = {
        [number.id]: number.data,
        [operator.id]: operator.data,
      };
      if (picker.data && picker.data.selection.selected.length > 0) value[picker.id] = picker.data;
      value.litteral_title = segment.fields.reduce((acc: StringMap, field: FilterField) => ({ ...acc, [field.id]: field.litteral_title }), {});
      return value;
    };

    const setUrlSegment = (segment: FilterSegment<FiltreBoxNavigationTriggerFilters>) => ({
      lists: segment.fields[0].data.map((url: string) => ({ url })),
      operator: segment.fields[1].data,
      litteral_title: {
        [segment.fields[0].id]: segment.fields[0].litteral_title,
      },
    });

    const update = () => {
      context.emit('update:modelValue', FiltreBoxNavigationTriggerMetaData.Create({
        ...commonFields.value,
        products: filters.value.products ?? {},
        categories: filters.value.categories ?? {},
        manufacturers: filters.value.manufacturers ?? {},
        urls: filters.value.urls ?? {},
        operatorBoxNav: segmentOperator.value,
        period_concerned: relevantPeriod.value,
        period_time: period.value,
      }));
    };

    const handleSaveSegment = (segment: FilterSegment<FiltreBoxNavigationTriggerFilters>) => {
      if (['products', 'categories', 'manufacturers'].includes(segment.id)) {
        filters.value[segment.id] = setPickerSegment(segment);
      } else {
        filters.value[segment.id] = setUrlSegment(segment);
      }
      update();
    };

    const handleRemoveSegment = (segmentName: keyof FiltreBoxNavigationTriggerFilters) => {
      filters.value[segmentName] = {};
      update();
    };

    const hasActiveSegments = () => (activeSegments.value.length > 1);

    watch([commonFields, relevantPeriod, period, segments, segmentOperator], update, { deep: true });

    onMounted(() => {
      if (scenarioType.value === 'bulk') {
        relevantPeriod.value = '1';
      }
    });

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      commonFields,
      periods,
      relevantPeriod,
      period,
      units,
      segments,
      logicalOperators,
      segmentOperator,
      activeSegments,
      filters,
      hasActiveSegments,
      handleSaveSegment,
      handleRemoveSegment,
      configuringSegment,
      activeItemsFilterPanel,
      scenarioType,
    };
  },
});
</script>
