<template>
  <div
    class="configuration-wrapper pt-2"
    :class="state.selectedOperator.meta.kind !== 'filtre' ? 'field-group-content' : ''"
  >
    <div class="configuration-wrapper-content">
      <SubPanelConfigContent
        :panels="panels"
      >
        <template #config_form>
          <component
            :is="state.selectedOperator.meta.component"
            v-if="data"
            v-model:modelValue="data"
            v-model:form-validation="formValidation"
            :selected-component="state.selectedOperator.meta.component"
          />
        </template>
        <template #analytics_data>
          <div
            v-if="analyticsData !== null"
            class="flex flex-column"
          >
            <template
              v-if="Object.prototype.hasOwnProperty.call(analyticsData, 'globalStatistics')"
            >
              <div class="flex flex-wrap font-bold pb-3">
                <i :class="`${t('campaigns.common.operatorAnalytics.globalStatistics.icon')} mr-2`" />
                {{ t('campaigns.common.operatorAnalytics.globalStatistics.header') }}
              </div>
              <span
                v-for="(value, key) in analyticsData.globalStatistics"
                :key="key"
                class="flex justify-content-between mb-3"
                style="color: #495057; font-size: 15px !important;"
              >
                <span class="mr-6">
                  <i :class="`${t('campaigns.common.operatorAnalytics.globalStatistics.items.icons.'+key)} fa-fw mr-1`" />
                  {{ t('campaigns.common.operatorAnalytics.globalStatistics.items.labels.'+key) }}
                </span>
                <div>
                  <span
                    class="text-sm font-semibold"
                    :class="{ 'mr-1': Object.prototype.hasOwnProperty.call(value, 'percent') }"
                  >{{ value.nb }}</span>
                  <span
                    v-if="Object.prototype.hasOwnProperty.call(value, 'percent')"
                    class="text-xs font-normal"
                  >({{ value.percent }}%)</span>
                </div>
              </span>
            </template>
            <template
              v-if="Object.prototype.hasOwnProperty.call(analyticsData, 'sendStatistics')"
            >
              <div class="flex flex-wrap font-bold pt-3 pb-3">
                <i :class="`${t('campaigns.common.operatorAnalytics.sendStatistics.icon')} mr-2`" />
                {{ t('campaigns.common.operatorAnalytics.sendStatistics.header') }}
              </div>
              <span
                v-for="(value, key) in analyticsData.sendStatistics"
                :key="key"
                class="flex justify-content-between mb-3"
                style="color: #495057; font-size: 15px !important;"
              >
                <span class="mr-6">
                  <i :class="`${t('campaigns.common.operatorAnalytics.sendStatistics.items.icons.'+key)} fa-fw mr-1`" />
                  {{ t('campaigns.common.operatorAnalytics.sendStatistics.items.labels.'+key) }}
                </span>
                <div>
                  <span
                    class="text-sm font-semibold"
                    :class="{ 'mr-1': Object.prototype.hasOwnProperty.call(value, 'percent') }"
                  >{{ formatNumbersInString(value.nb.toString()) }}</span>
                  <span
                    v-if="Object.prototype.hasOwnProperty.call(value, 'percent')"
                    class="text-xs font-normal"
                  >({{ value.percent }}%)</span>
                </div>
              </span>
            </template>
          </div>
        </template>
      </SubPanelConfigContent>
    </div>

    <div
      v-if="!configuringSegment && state.selectedOperator.meta.hasConfiguration"
      class="configuration-action-container flex p-2"
    >
      <span class="p-buttonset">
        <SpmButton
          :label="t('cancel')"
          icon="fa-regular fa-xmark"
          class-style="p-button p-button-secondary"
          @click="cancel"
        />
        <SpmButton
          :label="t('save')"
          icon="fa-regular fa-floppy-disk"
          class-style="p-button p-button-primary"
          @click="save"
        />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, onBeforeMount, ref, SetupContext, toRefs, watch, nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import ConfigComponents from '@/components/automated-scenarios/forms';
import {
  addHistory,
  AutomatedScenarioState as state,
  backOperatorSelect,
  resetLeftToolbar, resetSelectedOperator, resetDefaultDisplayUrl,
  setAnalyticsDataToOperator,
  setCurrentConfigurationForm,
  setOperatorData,
  setPersistence,
  refreshFlowchart,
} from '@/composables/AutomatedScenarios';
import { getComponentMetadata } from '@/components/automated-scenarios/metadata';
import { showToastError, showToastSuccess } from '@/helpers';
import SubPanelConfigContent from '@/components/automated-scenarios/SubPanelConfigContent.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { formatNumbersInString } from '@/helpers/Number';

export default defineComponent({
  name: 'OperatorEditForm',

  components: {
    SpmButton,
    ...ConfigComponents,
    SubPanelConfigContent,
    Button,
  },

  props: {
    signalSave: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    actionAfterSave: {
      type: Object as any,
      required: false,
      default: () => ({}),
    },
  },

  emits: [
    'reset-action-after-save',
  ],

  setup(props, context: SetupContext) {
    const { signalSave } = toRefs(props);
    const { actionAfterSave } = toRefs(props);
    const { t } = useI18n();
    const formValidation = ref<any>({});

    const data = ref<any>({});
    const configuringSegment = computed(() => state.configuringSegment);
    data.value = state.scenario.getOperatorData<any>(state.selectedOperator.operatorId)
      ? JSON.parse(JSON.stringify(state.scenario.getOperatorData<any>(state.selectedOperator.operatorId)))
      : null;

    setCurrentConfigurationForm(data.value);
    const analyticsData = computed(() => {
      const res = state.operators.filter((o) => o.operatorId === state.selectedOperator.operatorId);
      return res.length > 0 ? res[0].analyticsData : null;
    });

    const panels = computed(() => ([
      {
        name: 'config_form',
        label: t('campaigns.common.editForm.configuration'),
        show: state.selectedOperator.meta.hasConfiguration,
        showIfConfiguringSegment: true,
      },
      {
        name: 'analytics_data',
        label: `${t('campaigns.common.editForm.analytics')} (${t(`customDatePicker.${state.scenario.analyticsMode.dateInterval.interval}`)})`,
        show: analyticsData.value !== null,
        showIfConfiguringSegment: false,
      },
    ]));

    const save = async () => {
      const { meta: { component } } = state.selectedOperator;
      if (!component) {
        return;
      }

      const meta = getComponentMetadata(component);
      if (!meta) {
        return;
      }

      if (meta.PreValidate) {
        const predValidation = await meta.PreValidate(data.value);
        if (!predValidation.success) {
          await showToastError(t('errorMessages.FORM_ERROR'));
          return;
        }
      }

      if (meta.Validate) {
        try {
          const validation = await meta.Validate(data.value);
          if (!validation.success) {
            formValidation.value = validation.validate;
            await showToastError(t('errorMessages.FORM_ERROR'));
            return;
          }
        } catch (error) {
          console.error(error);
          return;
        }
      }

      if (meta.BeforeInsertData) {
        data.value = meta.BeforeInsertData(data.value);
      }
      setOperatorData(state.selectedOperator.operatorId, data.value);
      setPersistence(state.selectedOperator.operatorId);
      await nextTick();
      refreshFlowchart();
      showToastSuccess('savedSuccessful');
      resetSelectedOperator();
      resetDefaultDisplayUrl();
      resetLeftToolbar();
      addHistory('update_box', `${meta.Meta.label}`).then(() => {
        // console.log('addHistory');
      });

      if (meta.Callback) {
        meta.Callback(data.value);
      }

      if (actionAfterSave.value && actionAfterSave.value.action) {
        actionAfterSave.value.action();
        context.emit('reset-action-after-save');
      }
    };

    const cancel = () => {
      backOperatorSelect(true);
    };

    watch(() => data.value, () => {
      setCurrentConfigurationForm(data.value);
    }, { deep: true });

    watch(signalSave, async () => {
      await save();
    });

    onBeforeMount(async () => {
      if (state.selectedOperator.currentConfigurationForm !== null) {
        data.value = state.selectedOperator.currentConfigurationForm;
      }

      // Si les données analytics de cet operator sont null et que le mode analytics est activé, on les récupère
      if (analyticsData.value === null) {
        await setAnalyticsDataToOperator(state.selectedOperator.operatorId);
      }
    });

    return {
      t,
      panels,
      analyticsData,
      data,
      save,
      cancel,
      state,
      configuringSegment,
      formValidation,
    };
  },

  methods: { formatNumbersInString },
});
</script>

<style lang="scss">
.configuration-action-container {
  position: sticky;
  bottom: 0 !important;
  left: 0;
  width: calc(100% + 0.5rem) !important;
  height: 52.5px !important;
  margin-left: -0.5rem;
  padding: 0.5rem !important;
  border-top: 1px solid #dee2e6;
  background: #f8f9fa;
  .p-buttonset {
    width: 100%;
    .p-button {
      width: 48%;

      &:last-child {
        margin-left: 4%;
      }
    }
  }
}
</style>
