<template>
  <div
    :class="`p-${paddingSize}`"
  >
    <div
      class="field-wrapper mb-0"
    >
      <div class="flex flex-column">
        <div class="flex flex-column">
          <div class="flex gap-1">
            <div
              class="flex flex-column gap-1"
              :class="[size === 'small' ? 'xs:flex-row' : 'md:flex-row']"
            >
              <div>
                <Dropdown
                  v-if="showEditForm"
                  ref="dropdownRef"
                  v-model="selectedProperty"
                  filter
                  :options="propertiesOptions"
                  option-label="label"
                  option-group-label="label"
                  option-value="value"
                  option-group-children="items"
                  :placeholder="t('segmentations.labels.selectSegment')"
                  class="w-full"
                  @change="onChangeProperty"
                  @before-show="onBeforeShow"
                />
                <div
                  v-if="errorLevel && errorLevel==='property'"
                  class="mt-1"
                >
                  <small
                    class="p-error"
                  >
                    {{ t('segmentation.propErrorMsg') }}
                  </small>
                </div>
              </div>
              <SegmentationSimpleProperty
                v-if="selectedPropertyDefinition && ('logical_operators' in selectedPropertyDefinition || 'options' in selectedPropertyDefinition)"
                :key="`simple-property-${refreshPropertyDefinition}`"
                v-model:property-definition="selectedPropertyDefinition"
                v-model:property-config="blockOr"
                :field-id="selectedPropertyDefinition.id"
                :size="size"
              />
            </div>
            <div class="ml-auto flex">
              <PropertyDeletePanel
                :title="t('segmentation.orDeleteTitle')"
                type="blockOR"
                @remove-callback="deleteBlockOR"
              />
            </div>
          </div>
          <div
            v-if="selectedPropertyDefinition && 'sub_properties' in selectedPropertyDefinition"
            class="flex mt-2"
          >
            <SegmentationComplexProperty
              :key="`complex-property-${refreshPropertyDefinition}`"
              v-model:property-definition="selectedPropertyDefinition"
              v-model:property-config="blockOr"
              :level="level"
              :size="size"
              @refresh-component="refreshPropertyComponent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref, watch, computed,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';
import SegmentationSimpleProperty from '@/components/segmentations/SegmentationSimpleProperty.vue';
import SegmentationComplexProperty from '@/components/segmentations/SegmentationComplexProperty.vue';
import { store } from '@/store';
import PropertyDeletePanel from '@/components/segmentations/PropertyDeletePanel.vue';
import validateSegmentProperty from '@/components/segmentations/utils/utils';

export default defineComponent({
  name: 'SegmentationBlockOR',

  components: {
    PropertyDeletePanel,
    Dropdown,
    SegmentationSimpleProperty,
    SegmentationComplexProperty,
  },

  props: {
    blockOr: {
      type: Object,
      required: true,
    },

    indexBlockAnd: {
      type: Number,
      required: true,
    },

    indexBlockOr: {
      type: Number,
      required: true,
    },

    lengthBlockAnd: {
      type: Number,
      required: true,
    },

    size: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();
    const selectedTheme = ref(props.blockOr.theme);
    const refreshPropertyDefinition = ref(0);
    const level = ref(1);
    const errorLevel = ref();

    const selectedProperty = ref(props.blockOr.id ? props.blockOr.id : null);
    const propertiesOptions: any = ref([]);
    const selectedPropertyDefinition = ref<any>({});
    const selectedPropertyGroup = ref(null);

    const refreshPropertyComponent = () => {
      refreshPropertyDefinition.value += 1;
    };

    const init = () => {
      const groupProperties: any = store.getters['segmentationsEditor/getGroupProperties']();
      propertiesOptions.value = Object.entries(groupProperties).map(([key, values]) => ({
        label: t(`segmentations.${key}.label`),
        code: key,
        items: (values as any[]).map((value: any) => ({
          label: t(value.label),
          value: value.id,
        })),
      }));

      if (selectedProperty.value === null) {
        selectedPropertyDefinition.value = {};
      }

      refreshPropertyComponent();
    };

    const onChangeProperty = () => {
      if (selectedProperty.value) {
        const group = propertiesOptions.value.find((groupItem: any) => groupItem.items.some((item: any) => item.value === selectedProperty.value));
        selectedPropertyGroup.value = group ? group.code : null;
        selectedPropertyDefinition.value = store.getters['segmentationsEditor/getPropertyDefinition'](selectedPropertyGroup.value, selectedProperty.value);
        store.commit('segmentationsEditor/removePropertyError', `${props.indexBlockAnd}-${props.indexBlockOr}`);
      }
      refreshPropertyComponent();
    };

    const deleteBlockOR = () => {
      const { indexBlockAnd, indexBlockOr } = props;
      store.commit('segmentationsEditor/removeBlockOR', {
        indexBlockAND: indexBlockAnd,
        indexBlockOR: indexBlockOr,
      });
    };

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);
    const showEditForm = computed(() => {
      if (!Object.keys(props.blockOr).length
      || (currentSegmentConfig.value && currentSegmentConfig.value.configProperty.segmentId === props.blockOr.segmentId) || (props.blockOr.sub_properties)
      ) {
        return true;
      }
      return false;
    });

    const paddingSize = computed(() => (props.size === 'small' ? '1' : '2'));

    watch([selectedProperty], () => {
      const property: any = {
        id: selectedProperty.value,
        groupProperty: selectedPropertyGroup.value,
      };

      if (selectedProperty.value && selectedPropertyDefinition.value && 'sub_properties' in selectedPropertyDefinition.value) {
        property.sub_properties = [];
      }
      const { indexBlockAnd, indexBlockOr } = props;

      store.commit('segmentationsEditor/updateSegmentProperty', {
        indexBlockAND: indexBlockAnd,
        indexBlockOR: indexBlockOr,
        segmentData: property,
      });
      if (selectedPropertyDefinition.value && !selectedPropertyDefinition.value.sub_properties) {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', {
          configProperty: property,
          propertyDefinition: selectedPropertyDefinition.value,
        });
      }
      if (selectedPropertyDefinition.value.sub_properties) {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      }
    });

    watch(() => store.getters['segmentationsEditor/getErrors'], () => {
      const errorsArray = store.getters['segmentationsEditor/getErrors'];
      errorLevel.value = null;
      if (errorLevel.value === null) {
        errorsArray.properties.forEach((errorLevelString: string) => {
          if (errorLevelString === `${props.indexBlockAnd}-${props.indexBlockOr}`) {
            errorLevel.value = 'property';
          }
        });
      }
    }, { deep: true });

    const dropdownRef = ref();

    const onBeforeShow = async () => {
      if (currentSegmentConfig.value) {
        if (((Object.keys(props.blockOr).length && currentSegmentConfig.value.configProperty.segmentId !== props.blockOr.segmentId)) || !Object.keys(props.blockOr).length) {
          const { segmentId } = currentSegmentConfig.value.configProperty;
          const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
          if (validation && !validation.success) {
            store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
            dropdownRef.value.hide();
            return false;
          }
          store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
          store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
          store.commit('segmentationsEditor/setRefreshContactsNumber', true);
        }
      } else {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      }
    };

    onMounted(() => {
      init();
      onChangeProperty();
    });

    return {
      t,
      deleteBlockOR,
      selectedTheme,
      onChangeProperty,
      selectedProperty,
      selectedPropertyDefinition,
      propertiesOptions,
      level,
      errorLevel,
      refreshPropertyDefinition,
      refreshPropertyComponent,
      showEditForm,
      onBeforeShow,
      dropdownRef,
      paddingSize,
    };
  },
});
</script>

<style scoped lang="scss" >
.separator {
  border: none;
  border-top: 3px dotted #dbdbdb;
}

.positionAbsoluteTop {
  position: absolute;
  bottom: 3rem;
  right: 0;
}

.positionAbsoluteBottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
