// eslint-disable-next-line import/no-cycle
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { computed } from 'vue';
import {
  between, integer, minLength, required,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import moment from 'moment/moment';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator } from '@/helpers/CustomValidator';
import { formatNumberToCurrency } from '@/helpers/Number';
import { i18n } from '@/i18n';

let translation: any;
(async () => {
  translation = await i18n;
})();

export const stringLogicalOperators = (field: string, onlySpecificOperators = []) => {
  const validate = async (data: any): Promise<ErrorConfigForm> => {
    const rules = computed(() => ({
      [field]: {
        required,
        minlength: minLength(1),
      },
    }));
    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  };

  const logicalOperators = {
    string_equals: {
      label: 'segmentations.logicalOperators.string.equals',
      component: 'SegmentInputText',
      type: 'text',
      data: '',
      props: {},
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_not_equals: {
      label: 'segmentations.logicalOperators.string.not_equals',
      component: 'SegmentInputText',
      data: '',
      props: {},
      type: 'text',
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_in: {
      label: 'segmentations.logicalOperators.string.in',
      component: 'SegmentAutoComplete',
      type: 'text',
      data: '',
      props: {},
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_not_in: {
      label: 'segmentations.logicalOperators.string.not_in',
      component: 'SegmentAutoComplete',
      type: 'text',
      data: '',
      props: {},
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_contains: {
      label: 'segmentations.logicalOperators.string.contains',
      component: 'SegmentInputText',
      data: '',
      props: {},
      type: 'text',
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_not_contains: {
      label: 'segmentations.logicalOperators.string.not_contains',
      component: 'SegmentInputText',
      data: '',
      props: {},
      type: 'text',
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_starts_with: {
      label: 'segmentations.logicalOperators.string.starts_with',
      component: 'SegmentInputText',
      data: '',
      props: {},
      type: 'text',
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_not_starts_with: {
      label: 'segmentations.logicalOperators.string.not_starts_with',
      component: 'SegmentInputText',
      data: '',
      props: {},
      type: 'text',
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_ends_with: {
      label: 'segmentations.logicalOperators.string.ends_with',
      component: 'SegmentInputText',
      data: '',
      props: {},
      type: 'text',
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_not_ends_with: {
      label: 'segmentations.logicalOperators.string.not_ends_with',
      component: 'SegmentInputText',
      data: '',
      props: {},
      type: 'text',
      validate,
      getPreviewValue: (data: any) => data,
    },
    string_empty: {
      label: 'segmentations.logicalOperators.string.empty',
      getPreviewValue: () => '',
    },
    string_not_empty: {
      label: 'segmentations.logicalOperators.string.not_empty',
      getPreviewValue: () => '',
    },
  };

  if (onlySpecificOperators.length) {
    return onlySpecificOperators.reduce((acc, operator) => {
      acc[operator] = logicalOperators[operator];
      return acc;
    }, {});
  }

  return logicalOperators;
};

export const numberLogicalOperators = (field: string, onlySpecificOperators: any = [], countAggregate = false) => {
  const validate = async (data: any): Promise<ErrorConfigForm> => {
    const min = countAggregate ? 1 : 0;
    const rules = computed(() => ({
      [field]: {
        required,
        integer,
        between: between(min, 100000),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  };

  const getPreviewValue = (data: any, options: { currency: string }) => {
    let value = data;

    if (options.currency) {
      value = formatNumberToCurrency(data, options.currency, 0);
    }

    return value;
  };

  const getPreviewValueBetween = (data: any, options: { currency: string }) => {
    let { min } = data;
    let { max } = data;
    const and = translation.global.t('automatedScenarios.fields.and').toLowerCase();

    if (options.currency) {
      min = formatNumberToCurrency(min, options.currency, 0);
      max = formatNumberToCurrency(max, options.currency, 0);
    }

    return `${min} ${and} ${max}`;
  };

  const logicalOperators: any = {
    zero_times: {
      label: 'segmentations.logicalOperators.number.zero_times',
      component: false,
      data: { },
      props: {},
      getPreviewValue: () => '',
    },
    number_equals: {
      label: 'segmentations.logicalOperators.number.equals',
      type: 'text',
      component: 'SegmentInputNumber',
      data: 0,
      props: {},
      validate,
      getPreviewValue,
    },
    number_not_equals: {
      label: 'segmentations.logicalOperators.number.not_equals',
      type: 'text',
      component: 'SegmentInputNumber',
      data: 0,
      props: {},
      validate,
      getPreviewValue,
    },
    number_greater_than: {
      label: 'segmentations.logicalOperators.number.greater_than',
      type: 'text',
      component: 'SegmentInputNumber',
      data: 0,
      props: {},
      validate,
      getPreviewValue,
    },
    number_less_than: {
      label: 'segmentations.logicalOperators.number.less_than',
      type: 'text',
      component: 'SegmentInputNumber',
      data: 0,
      props: {},
      validate,
      getPreviewValue,
    },
    number_greater_than_or_equal_to: {
      label: 'segmentations.logicalOperators.number.greater_than_or_equal_to',
      type: 'text',
      component: 'SegmentInputNumber',
      data: 0,
      props: {},
      validate,
      getPreviewValue,
    },
    number_less_than_or_equal_to: {
      label: 'segmentations.logicalOperators.number.less_than_or_equal_to',
      type: 'text',
      component: 'SegmentInputNumber',
      data: 0,
      props: {},
      validate,
      getPreviewValue,
    },
    number_between: {
      label: 'segmentations.logicalOperators.number.between',
      component: 'SegmentNumberBetween',
      type: 'text',
      data: {
        min: 1,
        max: 1000000,
      },
      props: {
        suffix: '',
        min: 1,
        max: 1000000,
      },
      getPreviewValue: getPreviewValueBetween,
    },
    number_not_between: {
      label: 'segmentations.logicalOperators.number.not_between',
      component: 'SegmentNumberBetween',
      type: 'text',
      data: {
        min: 1,
        max: 1000000,
      },
      props: {
        suffix: '',
        min: 1,
        max: 1000000,
      },
      getPreviewValue: getPreviewValueBetween,
    },
  };

  if (!countAggregate) {
    delete logicalOperators.zero_times;
  }

  if (onlySpecificOperators.length) {
    return onlySpecificOperators.reduce((acc: any, operator: any) => {
      acc[operator] = logicalOperators[operator];
      return acc;
    }, {});
  }

  return logicalOperators;
};

export const dateStringLogicalOperators = (field: string, onlySpecificOperators = []) => {
  const validate = async (data: any): Promise<ErrorConfigForm> => {
    const rules = computed(() => ({
      [field]: {
        required,
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  };

  const getPreviewValue = (data: any) => {
    if (data.from) {
      const from = moment(data.from).format('DD/MM/YYYY');
      const to = moment(data.to).format('DD/MM/YYYY');
      const and = translation.global.t('automatedScenarios.fields.and').toLowerCase();
      return `${from} ${and} ${to}`;
    }
    return `${moment(data).format('DD/MM/YYYY')}`;
  };

  const logicalOperators = {
    dateString_equals: {
      label: 'segmentations.logicalOperators.dateString.equals',
      component: 'SegmentDateInput',
      type: 'text',
      data: '',
      props: {
        min: moment()
          .subtract(1, 'years')
          .toDate(),
        max: moment()
          .add(1, 'days')
          .toDate(),
      },
      validate,
      getPreviewValue,
    },
    dateString_not_equals: {
      label: 'segmentations.logicalOperators.dateString.not_equals',
      component: 'SegmentDateInput',
      type: 'text',
      data: '',
      props: {
        min: moment()
          .subtract(1, 'years')
          .toDate(),
        max: moment()
          .add(1, 'days')
          .toDate(),
      },
      validate,
      getPreviewValue,
    },
    dateString_before: {
      label: 'segmentations.logicalOperators.dateString.before',
      component: 'SegmentDateInput',
      type: 'text',
      data: '',
      props: {
        min: moment()
          .subtract(1, 'years')
          .toDate(),
        max: moment()
          .add(1, 'days')
          .toDate(),
      },
      validate,
      getPreviewValue,
    },
    dateString_after: {
      label: 'segmentations.logicalOperators.dateString.after',
      component: 'SegmentDateInput',
      type: 'text',
      data: '',
      props: {
        min: moment()
          .subtract(1, 'years')
          .toDate(),
        max: moment()
          .add(1, 'days')
          .toDate(),
      },
      validate,
      getPreviewValue,
    },
    dateString_on_or_before: {
      label: 'segmentations.logicalOperators.dateString.on_or_before',
      component: 'SegmentDateInput',
      type: 'text',
      data: '',
      props: {
        min: moment()
          .subtract(1, 'years')
          .toDate(),
        max: moment()
          .add(1, 'days')
          .toDate(),
      },
      validate,
      getPreviewValue,
    },
    dateString_on_or_after: {
      label: 'segmentations.logicalOperators.dateString.on_or_after',
      component: 'SegmentDateInput',
      type: 'text',
      data: '',
      props: {
        min: moment()
          .subtract(1, 'years')
          .toDate(),
        max: moment()
          .add(1, 'days')
          .toDate(),
      },
      validate,
      getPreviewValue,
    },
    dateString_between: {
      label: 'segmentations.logicalOperators.dateString.between',
      component: 'SegmentDateBetween',
      type: 'text',
      data: {
        from: moment()
          .toDate(),
        to: moment()
          .add(1, 'days')
          .toDate(),
      },
      props: {
        suffix: 'E',
        min: moment()
          .subtract(1, 'years')
          .toDate(),
        max: moment()
          .add(1, 'years')
          .toDate(),
      },
      validate,
      getPreviewValue,
    },
    dateString_not_between: {
      label: 'segmentations.logicalOperators.dateString.not_between',
      component: 'SegmentDateBetween',
      type: 'text',
      data: {
        from: moment()
          .toDate(),
        to: moment()
          .add(1, 'days')
          .toDate(),
      },
      props: {
        suffix: 'E',
        min: moment()
          .subtract(1, 'years')
          .toDate(),
        max: moment()
          .add(1, 'years')
          .toDate(),
      },
      validate,
      getPreviewValue,
    },
  };

  if (onlySpecificOperators.length) {
    return onlySpecificOperators.reduce((acc, operator) => {
      acc[operator] = logicalOperators[operator];
      return acc;
    }, {});
  }

  return logicalOperators;
};

export const dateNumberLogicalOperators = (field: string, onlySpecificOperators = []) => {
  const validate = async (data: any): Promise<ErrorConfigForm> => {
    const rules = computed(() => ({
      [field]: {
        value: {
          required,
          integer,
          between: between(0, 100000),
        },
        unit: {
          required,
          includesValuesValidator: includesValuesValidator([
            'DAY',
            'MONTH',
            'WEEK',
            'YEAR',
          ]),
        },
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  };

  const getPreviewValue = (data: any) => {
    switch (data.unit) {
      case 'DAY':
        return `${data.value} ${translation.global.t('automatedScenarios.fields.durations.day(s)')}`;
      case 'MONTH':
        return `${data.value} ${translation.global.t('automatedScenarios.fields.durations.month(s)')}`;
      case 'WEEK':
        return `${data.value} ${translation.global.t('automatedScenarios.fields.durations.week(s)')}`;
      case 'YEAR':
        return `${data.value} ${translation.global.t('automatedScenarios.fields.durations.year(s)')}`;
      default:
        if (data.value) {
          return data.value;
        }
        return '';
    }
  };

  const getHelpMessage = (dataValue: any) => {
    const { operator } = dataValue;
    const { data } = dataValue;

    let value = 1;
    let unit: any = 'DAY';
    if (data) {
      value = data.value;
      unit = data.unit;
    }
    let message = '';

    const today = moment().format('DD/MM/YYYY');
    const targetDate = moment().subtract(value, unit).format('DD/MM/YYYY');

    switch (operator) {
      case 'dateNumber_x_units_ago_exactly':
        message = translation.global.t('segmentations.helpMessage.dateNumber_x_units_ago_exactly', [
          targetDate,
        ]);
        break;
      case 'dateNumber_x_units_ago_at_least':
        message = translation.global.t('segmentations.helpMessage.dateNumber_x_units_ago_at_least', [
          targetDate,
        ]);
        break;
      case 'dateNumber_x_units_ago_at_most':
        message = translation.global.t('segmentations.helpMessage.dateNumber_x_units_ago_at_most', [
          targetDate,
          today,
        ]);
        break;
      default:
        message = '';
    }

    return {
      tooltip: translation.global.t('segmentations.helpMessage.tooltip_date'),
      message,
    };
  };

  const logicalOperators = {
    dateNumber_x_units_ago_exactly: {
      label: 'segmentations.logicalOperators.dateNumber.x_units_ago_exactly',
      component: 'PeriodSelector',
      data: { value: 1, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
          { value: 'WEEK', label: translation ? translation.global.t('automatedScenarios.fields.durations.week(s)') : '' },
          { value: 'YEAR', label: translation ? translation.global.t('automatedScenarios.fields.durations.year(s)') : '' },
        ],
      },
      validate,
      getPreviewValue,
      getHelpMessage,
    },
    dateNumber_x_units_ago_at_least: {
      label: 'segmentations.logicalOperators.dateNumber.x_units_ago_at_least',
      component: 'PeriodSelector',
      data: { value: 1, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
          { value: 'WEEK', label: translation ? translation.global.t('automatedScenarios.fields.durations.week(s)') : '' },
          { value: 'YEAR', label: translation ? translation.global.t('automatedScenarios.fields.durations.year(s)') : '' },
        ],
      },
      validate,
      getPreviewValue,
      getHelpMessage,
    },
    dateNumber_x_units_ago_at_most: {
      label: 'segmentations.logicalOperators.dateNumber.x_units_ago_at_most',
      component: 'PeriodSelector',
      data: { value: 1, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
          { value: 'WEEK', label: translation ? translation.global.t('automatedScenarios.fields.durations.week(s)') : '' },
          { value: 'YEAR', label: translation ? translation.global.t('automatedScenarios.fields.durations.year(s)') : '' },
        ],
      },
      validate,
      getPreviewValue,
      getHelpMessage,
    },
    // IN FUTURE, WE CAN ADD THESE OPERATORS LATER FOR CUSTOM DATA
    // dateNumber_exactly_in_x_units: {
    //   label: 'segmentations.logicalOperators.dateNumber.exactly_in_x_units',
    //   component: 'PeriodSelector',
    //   data: { value: 1, unit: 'DAY' },
    //   props: {
    //     title: 'none',
    //     units: [
    //       { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
    //       { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
    //     ],
    //   },
    //   validate,
    //   getPreviewValue,
    // },
    // dateNumber_at_least_in_x_units: {
    //   label: 'segmentations.logicalOperators.dateNumber.at_least_in_x_units',
    //   component: 'PeriodSelector',
    //   data: { value: 1, unit: 'DAY' },
    //   props: {
    //     title: 'none',
    //     units: [
    //       { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
    //       { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
    //     ],
    //   },
    //   validate,
    //   getPreviewValue,
    // },
    // dateNumber_at_most_in_x_units: {
    //   label: 'segmentations.logicalOperators.dateNumber.at_most_in_x_units',
    //   component: 'PeriodSelector',
    //   data: { value: 1, unit: 'DAY' },
    //   props: {
    //     title: 'none',
    //     units: [
    //       { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
    //       { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
    //     ],
    //   },
    //   validate,
    //   getPreviewValue,
    // },
  };

  if (onlySpecificOperators.length) {
    return onlySpecificOperators.reduce((acc, operator) => {
      acc[operator] = logicalOperators[operator];
      return acc;
    }, {});
  }

  return logicalOperators;
};


export const dateNullLogicalOperators = (field: string, onlySpecificOperators = []) => {
  const logicalOperators = {
    dateNull_is_not_null: {
      label: 'segmentations.logicalOperators.dateNull.is_not_null',
      component: false,
      data: {},
      props: {},
      getPreviewValue: () => '',
    },
    dateNull_is_null: {
      label: 'segmentations.logicalOperators.dateNull.is_null',
      component: false,
      data: {},
      props: {},
      getPreviewValue: () => '',
    },
  };

  if (onlySpecificOperators.length) {
    return onlySpecificOperators.reduce((acc, operator) => {
      acc[operator] = logicalOperators[operator];
      return acc;
    }, {});
  }

  return logicalOperators;
};

export const anniversaryNumberLogicalOperators = (field: string, onlySpecificOperators = []) => {
  const validate = async (data: any): Promise<ErrorConfigForm> => {
    const rules = computed(() => ({
      [field]: {
        value: {
          required,
          integer,
          between: between(0, 100000),
        },
        unit: {
          required,
          includesValuesValidator: includesValuesValidator([
            'DAY',
            'MONTH',
          ]),
        },
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  };

  const getPreviewValue = (suffix = '') => {
    const previewFunction = (data: any) => {
      if (data.unit !== 'DAY' && data.unit !== 'MONTH') {
        return data.value;
      }

      const unitPreviewValue = data.unit === 'DAY'
        ? translation.global.t('automatedScenarios.fields.durations.day(s)')
        : translation.global.t('automatedScenarios.fields.durations.month(s)');

      return `${data.value} ${unitPreviewValue} ${suffix}`.trim();
    };
    return previewFunction;
  };

  const beforeValueText = translation.global.t('segmentations.logicalOperators.anniversaryNumber.label');

  const getHelpMessage = (dataValue: any) => {
    const { operator } = dataValue;
    const { data } = dataValue;

    let value = 1;
    let unit: any = 'DAY';
    if (data) {
      value = data.value;
      unit = data.unit;
    }
    let message = '';

    const today = moment().format('DD/MM');
    const targetDate = moment().subtract(value, unit).format('DD/MM');
    const futureDate = moment().add(value, unit).format('DD/MM');

    switch (operator) {
      case 'anniversaryNumber_x_units_ago_exactly':
        message = translation.global.t('segmentations.helpMessage.anniversaryNumber_x_units_ago_exactly', [
          targetDate,
        ]);
        break;
      case 'anniversaryNumber_x_units_ago_at_least':
        message = translation.global.t('segmentations.helpMessage.anniversaryNumber_x_units_ago_at_least', [
          targetDate,
        ]);
        break;
      case 'anniversaryNumber_x_units_ago_at_most':
        message = translation.global.t('segmentations.helpMessage.anniversaryNumber_x_units_ago_at_most', [
          targetDate,
          today,
        ]);
        break;
      case 'anniversaryNumber_exactly_in_x_units':
        message = translation.global.t('segmentations.helpMessage.anniversaryNumber_exactly_in_x_units', [
          futureDate,
        ]);
        break;
      case 'anniversaryNumber_at_least_in_x_units':
        message = translation.global.t('segmentations.helpMessage.anniversaryNumber_at_least_in_x_units', [
          futureDate,
        ]);
        break;
      case 'anniversaryNumber_at_most_in_x_units':
        message = translation.global.t('segmentations.helpMessage.anniversaryNumber_at_most_in_x_units', [
          today,
          futureDate,
        ]);
        break;
      default:
        message = '';
    }

    return {
      tooltip: translation.global.t('segmentations.helpMessage.tooltip_date'),
      message,
    };
  };

  const logicalOperators = {
    anniversaryNumber_x_units_ago_exactly: {
      label: 'segmentations.logicalOperators.anniversaryNumber.x_units_ago_exactly',
      component: 'PeriodSelector',
      data: { value: 0, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
        ],
      },
      validate,
      beforeValueText,
      getPreviewValue: getPreviewValue(translation.global.t('segmentations.logicalOperators.anniversaryNumber.x_units_ago_exactly_suffix')),
      getHelpMessage,
    },
    anniversaryNumber_x_units_ago_at_least: {
      label: 'segmentations.logicalOperators.anniversaryNumber.x_units_ago_at_least',
      component: 'PeriodSelector',
      data: { value: 1, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
        ],
      },
      validate,
      beforeValueText,
      getPreviewValue: getPreviewValue(translation.global.t('segmentations.logicalOperators.anniversaryNumber.x_units_ago_at_least_suffix')),
      getHelpMessage,
    },
    anniversaryNumber_x_units_ago_at_most: {
      label: 'segmentations.logicalOperators.anniversaryNumber.x_units_ago_at_most',
      component: 'PeriodSelector',
      data: { value: 1, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
        ],
      },
      validate,
      beforeValueText,
      getPreviewValue: getPreviewValue(translation.global.t('segmentations.logicalOperators.anniversaryNumber.x_units_ago_at_most_suffix')),
      getHelpMessage,
    },
    anniversaryNumber_exactly_in_x_units: {
      label: 'segmentations.logicalOperators.anniversaryNumber.exactly_in_x_units',
      component: 'PeriodSelector',
      data: { value: 1, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
        ],
      },
      validate,
      beforeValueText,
      getPreviewValue: getPreviewValue(),
      getHelpMessage,
    },
    anniversaryNumber_at_least_in_x_units: {
      label: 'segmentations.logicalOperators.anniversaryNumber.at_least_in_x_units',
      component: 'PeriodSelector',
      data: { value: 1, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
        ],
      },
      validate,
      beforeValueText,
      getPreviewValue: getPreviewValue(),
      getHelpMessage,
    },
    anniversaryNumber_at_most_in_x_units: {
      label: 'segmentations.logicalOperators.anniversaryNumber.at_most_in_x_units',
      component: 'PeriodSelector',
      data: { value: 1, unit: 'DAY' },
      props: {
        title: 'none',
        minValue: 0,
        units: [
          { value: 'DAY', label: translation ? translation.global.t('automatedScenarios.fields.durations.day(s)') : '' },
          { value: 'MONTH', label: translation ? translation.global.t('automatedScenarios.fields.durations.month(s)') : '' },
        ],
      },
      validate,
      beforeValueText,
      getPreviewValue: getPreviewValue(),
      getHelpMessage,
    },
  };

  if (onlySpecificOperators.length) {
    return onlySpecificOperators.reduce((acc, operator) => {
      acc[operator] = logicalOperators[operator];
      return acc;
    }, {});
  }

  return logicalOperators;
};

export const selectionBasedLogicalOperators = (field: string, options: any, onlySpecificOperators = []) => {
  const validate = async (data: any): Promise<ErrorConfigForm> => {
    const rules = computed(() => ({
      [field]: {
        required,
        minlength: minLength(3),
      },
    }));
    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  };

  // eslint-disable-next-line no-shadow
  const getPreviewValue = (data: any, options = []) => {
    let dataToUse = Object.prototype.toString.call(data) === '[object Object]' ? Object.keys(data) : data;
    dataToUse = Array.isArray(dataToUse) ? dataToUse : [dataToUse];

    dataToUse = dataToUse.map((item: any) => item.toString());
    return options.filter(
      (option: any) => dataToUse.includes(option.value.toString()),
    ).map((option: any) => translation.global.t(option.label)).join(', ');
  };

  const logicalOperators = {
    selectionBased_equals: {
      label: 'segmentations.logicalOperators.selectionBased.equals',
      component: 'SegmentDropdown',
      type: 'text',
      data: '',
      props: {
        optionValue: 'value',
        optionLabel: 'label',
        options: () => options,
      },
      getPreviewValue,
    },
    selectionBased_not_equals: {
      label: 'segmentations.logicalOperators.selectionBased.not_equals',
      component: 'SegmentDropdown',
      type: 'text',
      data: '',
      props: {
        optionValue: 'value',
        optionLabel: 'label',
        options: () => options,
      },
      getPreviewValue,
    },
    selectionBased_in: {
      label: 'segmentations.logicalOperators.selectionBased.in',
      component: 'SegmentMultiDropdown',
      type: 'text',
      data: '',
      props: {
        optionValue: 'value',
        optionLabel: 'label',
        options: () => options,
      },
      getPreviewValue,
    },
    selectionBased_not_in: {
      label: 'segmentations.logicalOperators.selectionBased.not_in',
      component: 'SegmentMultiDropdown',
      type: 'text',
      data: '',
      props: {
        optionValue: 'value',
        optionLabel: 'label',
        options: () => options,
      },
      getPreviewValue,
    },
  };

  if (onlySpecificOperators.length) {
    return onlySpecificOperators.reduce((acc, operator) => {
      acc[operator] = logicalOperators[operator];
      return acc;
    }, {});
  }

  return logicalOperators;
};

export const geographicalAreaLogicalOperators = (field: string) => {
  const validate = async (data: any): Promise<ErrorConfigForm> => {
    const rules = computed(() => ({
      [field]: {
        address: { required },
        longitude: { between: between(-180, 180) },
        latitude: { between: between(-90, 90) },
      },
    }));
    const v$ = useVuelidate(rules, data);

    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  };

  const getPreviewValue = (data: any) => translation.global.t('segmentations.preview.geographicalArea', [
    data.radius,
    data.unit,
    data.address,
  ]);

  return {
    area_in: {
      label: 'segmentations.logicalOperators.geographicalArea.area_in',
      component: 'SegmentGeographicalArea',
      data: {
        address: '',
        radius: 50,
        longitude: 0,
        latitude: 0,
        unit: 'km',
      },
      props: {},
      validate,
      getPreviewValue,
    },
    area_not_in: {
      label: 'segmentations.logicalOperators.geographicalArea.area_not_in',
      component: 'SegmentGeographicalArea',
      data: {
        address: '',
        radius: 50,
        longitude: 0,
        latitude: 0,
        unit: 'km',
      },
      props: {},
      validate,
      getPreviewValue,
    },
  };
};

export const selectionBasedIDLogicalOperators = (field: string, onlySpecificOperators = []) => {
  const validate = async (data: any): Promise<ErrorConfigForm> => {
    const rules = computed(() => ({
      [field]: {
        required,
      },
    }));
    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  };

  const logicalOperators = {
    selectionBasedID_equals: {
      label: 'segmentations.logicalOperators.selectionBasedID.equals',
      component: 'SegmentInputText',
      type: 'text',
      data: '',
      props: {},
      validate,
      getPreviewValue: (data: any) => data,
    },
    selectionBasedID_not_equals: {
      label: 'segmentations.logicalOperators.selectionBasedID.not_equals',
      component: 'SegmentInputText',
      type: 'text',
      data: '',
      props: {},
      validate,
      getPreviewValue: (data: any) => data,
    },
    selectionBasedID_in: {
      label: 'segmentations.logicalOperators.selectionBasedID.in',
      component: 'SegmentAutoComplete',
      data: '',
      props: {
        multiple: true,
      },
      validate,
      getPreviewValue: (data: any) => data,
    },
    selectionBasedID_not_in: {
      label: 'segmentations.logicalOperators.selectionBasedID.not_in',
      component: 'SegmentAutoComplete',
      data: '',
      props: {
        multiple: true,
      },
      validate,
      getPreviewValue: (data: any) => data,
    },
  };

  if (onlySpecificOperators.length) {
    return onlySpecificOperators.reduce((acc, operator) => {
      acc[operator] = logicalOperators[operator];
      return acc;
    }, {});
  }

  return logicalOperators;
};
