<template>
  <PanelAccordion
    :key="panelsKeys"
    :panels="panels"
  >
    <template #filter_properties>
      <FilterGlobalInformations
        v-model="commonFields"
        v-model:form-validation="formValidationErrors"
      />
      <div
        class="field-group pt-2"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <ButtonGroup
                v-model="grantAdditionalDelay"
                :options="options"
                title="automatedScenarios.fields.grantAdditionalDelay"
              />
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="grant_additional_delay"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="grantAdditionalDelay === '1'"
        class="field-group pt-2"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <PeriodSelector
                v-model="delay"
                :units="units"
                title="automatedScenarios.fields.duration"
              />
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="extra_time"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #segmentation_criteria>
      <Message
        severity="info"
        class="mt-0"
        :closable="false"
      >
        Créez des segments basés sur les caractéristiques ou les actions des contacts. Ceux qui correspondent aux critères définis seront dirigés vers la sortie appropriée du flux.
      </Message>
      <SegmentationsPanel
        v-if="segmentsConfiguration !== null"
        v-model:model-value="segmentsConfiguration"
        origin="workflow_filter_contact_segmentation"
        :show-prompt-a-i="true"
        size="small"
      />
    </template>
  </PanelAccordion>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount,
  PropType,
  ref,
  SetupContext, toRaw,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import { Period } from '@/types/automated-scenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import FiltreContactSegmentationMetadata, {
  FiltreContactSegmentationData,
} from '@/components/automated-scenarios/metadata/filtres/FiltreContactSegmentationMetadata';
import SegmentationsPanel from '@/components/segmentations/SegmentationsPanel.vue';
import PanelAccordion, { SpmPanelAccordionInterface } from '@/components/automated-scenarios/PanelAccordion.vue';
import Message from 'primevue/message';
import FilterGlobalInformations from '../../fields/FilterGlobalInformations.vue';

export default defineComponent({
  name: 'FiltreContactSegmentation',
  components: {
    PanelAccordion,
    SegmentationsPanel,
    FieldErrors,
    FilterGlobalInformations,
    ButtonGroup,
    PeriodSelector,
    Message,
  },

  props: {
    modelValue: {
      type: Object as PropType<FiltreContactSegmentationData>,
      required: true,
      default() {
        return FiltreContactSegmentationMetadata.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: FiltreContactSegmentationData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const panelsKeys = ref(0);
    const panels: SpmPanelAccordionInterface[] = [
      {
        id: 'filter_properties',
        title: 'Propriétés du filtre',
      },
      {
        id: 'segmentation_criteria',
        title: 'Critères de segmentation',
      },
    ];

    const commonFields = ref({
      custom_title: props.modelValue.custom_title,
      custom_description: props.modelValue.custom_description,
      outputs_number: props.modelValue.outputs_number,
    });

    const grantAdditionalDelay = ref(props.modelValue.grant_additional_delay);
    const delay = ref<Period>({
      unit: props.modelValue.extra_time ? props.modelValue.extra_time.unit : 'DAY',
      value: props.modelValue.extra_time ? props.modelValue.extra_time.value : 1,
    });
    const options = [
      { value: '0', label: t('no') },
      { value: '1', label: t('yes') },
    ];
    const units = [
      { value: 'SECOND', label: t('automatedScenarios.fields.durations.second(s)') },
      { value: 'MINUTE', label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: 'HOUR', label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: 'DAY', label: t('automatedScenarios.fields.durations.day(s)') },
    ];

    const segmentsConfiguration: any = ref(null);

    const update = () => {
      context.emit('update:modelValue', FiltreContactSegmentationMetadata.Create({
        ...commonFields.value,
        grant_additional_delay: grantAdditionalDelay.value,
        extra_time: delay.value,
        segmentation_config: segmentsConfiguration.value,
      }));
    };

    watch([commonFields, grantAdditionalDelay, delay, segmentsConfiguration], update, { deep: true });

    onBeforeMount(() => {
      if (props.modelValue.segmentation_config) {
        segmentsConfiguration.value = toRaw(props.modelValue.segmentation_config);
      }
      update();
    });

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      commonFields,
      grantAdditionalDelay,
      options,
      delay,
      units,
      panelsKeys,
      panels,
      segmentsConfiguration,
    };
  },
});
</script>
